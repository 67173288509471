// Cookies
export const ANONYMOUS_ID_COOKIE_NAME = "vectaraAnonymousId";

// Paths
export const SURVEY_PATH = "welcome";
export const SECURITY_PATH = "security";
export const PROFILE_PATH = "profile";
export const DELETE_ACCOUNT_PATH = "deleteAccount";
export const CORPORA_PATH = "corpora";
export const CORPUS_PATH = "corpus/key";
export const CORPUS_PATH_V1 = "corpus";
export const BILLING_PATH = "billing";
export const UPDATES_PATH = "updates";

// Sample corpus
export const SAMPLE_CORPUS_NAME = "black-holes-sample-data";
export const SAMPLE_CORPUS_KEY = "black-holes-sample-data";
export const SAMPLE_CORPUS_DESCRIPTION = "Information about black holes — as it turns out, they really do suck.";

// Rerankers
export const MMR_RERANKER_ID = 272725718;
export const SLINGSHOT_RERANKER_ID = 272725719;
export const UDF_RERANKER_ID = 272725722;

// LLMs
export const LLM = {
  mockingbird: "mockingbird-1.0",
  gpt4Turbo: "gpt-4-turbo",
  gpt4o: "gpt-4o",
  gpt4: "gpt-4",
  gpt35Turbo: "gpt-3.5-turbo",
  llama: "llama-3-70b"
};

// Generation presets
export const CUSTOM_PROMPT_TEMPLATE_VALUE = "custom_prompt_template";

// Feature Flags

// Toggle this to true to show:
//   - the trial plan UI elements
//   - the new plan table (pro, standard, enterprise, vpc, on-prem)
export const IS_NEW_PRICING_PLAN_ACTIVE = false;
