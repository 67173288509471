import { Auth } from "@aws-amplify/auth";
import { IS_PROD } from "../../backendConfig";
import { orySdk } from "./orySdk";
import { getCustomerId, getSession, setSession } from "../localStorage/customer";
import { StatusCode } from "../../generated_protos/status_pb";
import { AdminServicePromiseClient } from "../../generated_protos/services_grpc_web_pb";
import { ExtendSession } from "../../admin/UserEndpoint";
import { jwtDecode } from "jwt-decode";

/**
 * JWTs are used to authenticate API calls. This function retrieves
 * the cached JWT or refreshes it if it has expired. Don't call this
 * function directly. Use the getJwt method returned by the
 * useUserContext hook instead.
 */
export const refreshJwt = async (isOryAuth: boolean, AdminService: AdminServicePromiseClient) => {
  let jwt;
  if (isOryAuth) {
    jwt = await getOryJwt(AdminService);
  } else {
    const session = await Auth.currentSession();
    jwt = session.getIdToken().getJwtToken();
  }

  // Expose JWT for tests.
  if (!IS_PROD) {
    // @ts-expect-error Doesn't exist on window.
    window.jwt = jwt;
  }

  return jwt;
};

export const refreshOrySession = async () => {
  try {
    const tokenTemplateName = process.env.REACT_APP_ORY_JWT_TEMPLATE_NAME;

    const response = await orySdk.toSession({
      tokenizeAs: tokenTemplateName,
    });

    const result = response.data;
    const jwtExpiry = jwtDecode(result?.tokenized ?? '')?.exp;

    setSession({
      expiresAt: result?.expires_at,
      jwt: result?.tokenized,
      sessionId: result?.id,
      email: result.identity?.traits?.email,
      username: result.identity?.traits?.user_info?.username,
      customerId: result.identity?.traits?.user_info?.customer_id,
      jwtExpiry: jwtExpiry ? new Date(jwtExpiry * 1000) : null
    });

    return {
      id: result?.id,
      expiresAt: result?.expires_at,
      tokenized: result?.tokenized,
      customerId: result.identity?.traits?.user_info?.customer_id,
      username: result.identity?.traits?.user_info?.username,
      email: result.identity?.traits?.email,
      userId: result.identity?.id
    };
  } catch (e: any) {
    if (e.response.status !== 200) {
      setSession(null);
      return null;
    }
    console.log(e);
  }
};

export const getOryJwt = async (AdminService: AdminServicePromiseClient) => {
  const currentDateTime = new Date();

  const existingSession = getSession();
  const currentSessionExpiresAt = new Date(existingSession?.jwtExpiry ?? "");

  // If session is valid, checking from localstorage
  const timeToExpiryInMs = !existingSession?.jwtExpiry
    ? -1
    : currentSessionExpiresAt.getTime() - currentDateTime.getTime();
  if (timeToExpiryInMs >= 120000 /* 2 mintues */) {
    return existingSession?.jwt;
  } else {
    // If session is valid and less than 2 mintues to expiry, extend the session
    const extendSession = await ExtendSession(
      existingSession?.jwt ?? "",
      AdminService,
      getCustomerId() ?? "",
      existingSession?.sessionId ?? ""
    );

    if (extendSession.status?.code !== StatusCode.OK) {
      throw new Error(`failed to refresh token: ${extendSession}`);
    }

    const newSessionToken = await refreshOrySession();
    return newSessionToken?.tokenized;
  }

  // If session is not valid, get a new session
  return;
};
