// source: serving.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {missingRequire} reports error on implicit type usages.
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!
/* eslint-disable */
// @ts-nocheck

var jspb = require('google-protobuf');
var goog = jspb;
var global = (function() { return this || window || global || self || Function('return this')(); }).call(null);

var protoc$gen$openapiv2_options_annotations_pb = require('./protoc-gen-openapiv2/options/annotations_pb.js');
goog.object.extend(proto, protoc$gen$openapiv2_options_annotations_pb);
var status_pb = require('./status_pb.js');
goog.object.extend(proto, status_pb);
goog.exportSymbol('proto.com.vectara.serving.Attribute', null, global);
goog.exportSymbol('proto.com.vectara.serving.BatchQueryRequest', null, global);
goog.exportSymbol('proto.com.vectara.serving.BatchQueryResponse', null, global);
goog.exportSymbol('proto.com.vectara.serving.Chat', null, global);
goog.exportSymbol('proto.com.vectara.serving.ChatRequest', null, global);
goog.exportSymbol('proto.com.vectara.serving.CitationParams', null, global);
goog.exportSymbol('proto.com.vectara.serving.CitationStyle', null, global);
goog.exportSymbol('proto.com.vectara.serving.CorpusKey', null, global);
goog.exportSymbol('proto.com.vectara.serving.CorpusKey.Semantics', null, global);
goog.exportSymbol('proto.com.vectara.serving.CustomDimension', null, global);
goog.exportSymbol('proto.com.vectara.serving.FactualConsistency', null, global);
goog.exportSymbol('proto.com.vectara.serving.LinearInterpolation', null, global);
goog.exportSymbol('proto.com.vectara.serving.MMRConfig', null, global);
goog.exportSymbol('proto.com.vectara.serving.PerformanceMetrics', null, global);
goog.exportSymbol('proto.com.vectara.serving.QueryRequest', null, global);
goog.exportSymbol('proto.com.vectara.serving.QueryRequest.ContextConfig', null, global);
goog.exportSymbol('proto.com.vectara.serving.QueryRequest.RerankingConfig', null, global);
goog.exportSymbol('proto.com.vectara.serving.QueryResponsePart', null, global);
goog.exportSymbol('proto.com.vectara.serving.Response', null, global);
goog.exportSymbol('proto.com.vectara.serving.ResponseSet', null, global);
goog.exportSymbol('proto.com.vectara.serving.ResponseSet.Document', null, global);
goog.exportSymbol('proto.com.vectara.serving.SummarizationRequest', null, global);
goog.exportSymbol('proto.com.vectara.serving.SummarizationRequest.ModelParams', null, global);
goog.exportSymbol('proto.com.vectara.serving.Summary', null, global);
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.com.vectara.serving.CustomDimension = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.com.vectara.serving.CustomDimension, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.com.vectara.serving.CustomDimension.displayName = 'proto.com.vectara.serving.CustomDimension';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.com.vectara.serving.LinearInterpolation = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.com.vectara.serving.LinearInterpolation, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.com.vectara.serving.LinearInterpolation.displayName = 'proto.com.vectara.serving.LinearInterpolation';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.com.vectara.serving.CorpusKey = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.com.vectara.serving.CorpusKey.repeatedFields_, null);
};
goog.inherits(proto.com.vectara.serving.CorpusKey, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.com.vectara.serving.CorpusKey.displayName = 'proto.com.vectara.serving.CorpusKey';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.com.vectara.serving.SummarizationRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.com.vectara.serving.SummarizationRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.com.vectara.serving.SummarizationRequest.displayName = 'proto.com.vectara.serving.SummarizationRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.com.vectara.serving.SummarizationRequest.ModelParams = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.com.vectara.serving.SummarizationRequest.ModelParams, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.com.vectara.serving.SummarizationRequest.ModelParams.displayName = 'proto.com.vectara.serving.SummarizationRequest.ModelParams';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.com.vectara.serving.QueryRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.com.vectara.serving.QueryRequest.repeatedFields_, null);
};
goog.inherits(proto.com.vectara.serving.QueryRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.com.vectara.serving.QueryRequest.displayName = 'proto.com.vectara.serving.QueryRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.com.vectara.serving.QueryRequest.ContextConfig = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.com.vectara.serving.QueryRequest.ContextConfig, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.com.vectara.serving.QueryRequest.ContextConfig.displayName = 'proto.com.vectara.serving.QueryRequest.ContextConfig';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.com.vectara.serving.QueryRequest.RerankingConfig = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.com.vectara.serving.QueryRequest.RerankingConfig, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.com.vectara.serving.QueryRequest.RerankingConfig.displayName = 'proto.com.vectara.serving.QueryRequest.RerankingConfig';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.com.vectara.serving.CitationParams = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.com.vectara.serving.CitationParams, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.com.vectara.serving.CitationParams.displayName = 'proto.com.vectara.serving.CitationParams';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.com.vectara.serving.ChatRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.com.vectara.serving.ChatRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.com.vectara.serving.ChatRequest.displayName = 'proto.com.vectara.serving.ChatRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.com.vectara.serving.Chat = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, 500, null, null);
};
goog.inherits(proto.com.vectara.serving.Chat, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.com.vectara.serving.Chat.displayName = 'proto.com.vectara.serving.Chat';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.com.vectara.serving.Attribute = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.com.vectara.serving.Attribute, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.com.vectara.serving.Attribute.displayName = 'proto.com.vectara.serving.Attribute';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.com.vectara.serving.FactualConsistency = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, 500, null, null);
};
goog.inherits(proto.com.vectara.serving.FactualConsistency, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.com.vectara.serving.FactualConsistency.displayName = 'proto.com.vectara.serving.FactualConsistency';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.com.vectara.serving.Summary = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, 500, proto.com.vectara.serving.Summary.repeatedFields_, null);
};
goog.inherits(proto.com.vectara.serving.Summary, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.com.vectara.serving.Summary.displayName = 'proto.com.vectara.serving.Summary';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.com.vectara.serving.Response = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.com.vectara.serving.Response.repeatedFields_, null);
};
goog.inherits(proto.com.vectara.serving.Response, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.com.vectara.serving.Response.displayName = 'proto.com.vectara.serving.Response';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.com.vectara.serving.ResponseSet = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, 500, proto.com.vectara.serving.ResponseSet.repeatedFields_, null);
};
goog.inherits(proto.com.vectara.serving.ResponseSet, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.com.vectara.serving.ResponseSet.displayName = 'proto.com.vectara.serving.ResponseSet';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.com.vectara.serving.ResponseSet.Document = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.com.vectara.serving.ResponseSet.Document.repeatedFields_, null);
};
goog.inherits(proto.com.vectara.serving.ResponseSet.Document, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.com.vectara.serving.ResponseSet.Document.displayName = 'proto.com.vectara.serving.ResponseSet.Document';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.com.vectara.serving.BatchQueryRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.com.vectara.serving.BatchQueryRequest.repeatedFields_, null);
};
goog.inherits(proto.com.vectara.serving.BatchQueryRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.com.vectara.serving.BatchQueryRequest.displayName = 'proto.com.vectara.serving.BatchQueryRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.com.vectara.serving.BatchQueryResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, 500, proto.com.vectara.serving.BatchQueryResponse.repeatedFields_, null);
};
goog.inherits(proto.com.vectara.serving.BatchQueryResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.com.vectara.serving.BatchQueryResponse.displayName = 'proto.com.vectara.serving.BatchQueryResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.com.vectara.serving.QueryResponsePart = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, 500, proto.com.vectara.serving.QueryResponsePart.repeatedFields_, null);
};
goog.inherits(proto.com.vectara.serving.QueryResponsePart, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.com.vectara.serving.QueryResponsePart.displayName = 'proto.com.vectara.serving.QueryResponsePart';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.com.vectara.serving.PerformanceMetrics = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.com.vectara.serving.PerformanceMetrics, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.com.vectara.serving.PerformanceMetrics.displayName = 'proto.com.vectara.serving.PerformanceMetrics';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.com.vectara.serving.MMRConfig = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.com.vectara.serving.MMRConfig, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.com.vectara.serving.MMRConfig.displayName = 'proto.com.vectara.serving.MMRConfig';
}



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.com.vectara.serving.CustomDimension.prototype.toObject = function(opt_includeInstance) {
  return proto.com.vectara.serving.CustomDimension.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.com.vectara.serving.CustomDimension} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.vectara.serving.CustomDimension.toObject = function(includeInstance, msg) {
  var f, obj = {
    name: jspb.Message.getFieldWithDefault(msg, 1, ""),
    weight: jspb.Message.getFloatingPointFieldWithDefault(msg, 2, 0.0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.com.vectara.serving.CustomDimension}
 */
proto.com.vectara.serving.CustomDimension.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.com.vectara.serving.CustomDimension;
  return proto.com.vectara.serving.CustomDimension.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.com.vectara.serving.CustomDimension} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.com.vectara.serving.CustomDimension}
 */
proto.com.vectara.serving.CustomDimension.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setWeight(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.com.vectara.serving.CustomDimension.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.com.vectara.serving.CustomDimension.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.com.vectara.serving.CustomDimension} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.vectara.serving.CustomDimension.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getWeight();
  if (f !== 0.0) {
    writer.writeDouble(
      2,
      f
    );
  }
};


/**
 * optional string name = 1;
 * @return {string}
 */
proto.com.vectara.serving.CustomDimension.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.com.vectara.serving.CustomDimension} returns this
 */
proto.com.vectara.serving.CustomDimension.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional double weight = 2;
 * @return {number}
 */
proto.com.vectara.serving.CustomDimension.prototype.getWeight = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 2, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.com.vectara.serving.CustomDimension} returns this
 */
proto.com.vectara.serving.CustomDimension.prototype.setWeight = function(value) {
  return jspb.Message.setProto3FloatField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.com.vectara.serving.LinearInterpolation.prototype.toObject = function(opt_includeInstance) {
  return proto.com.vectara.serving.LinearInterpolation.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.com.vectara.serving.LinearInterpolation} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.vectara.serving.LinearInterpolation.toObject = function(includeInstance, msg) {
  var f, obj = {
    lambda: jspb.Message.getFloatingPointFieldWithDefault(msg, 1, 0.0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.com.vectara.serving.LinearInterpolation}
 */
proto.com.vectara.serving.LinearInterpolation.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.com.vectara.serving.LinearInterpolation;
  return proto.com.vectara.serving.LinearInterpolation.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.com.vectara.serving.LinearInterpolation} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.com.vectara.serving.LinearInterpolation}
 */
proto.com.vectara.serving.LinearInterpolation.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setLambda(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.com.vectara.serving.LinearInterpolation.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.com.vectara.serving.LinearInterpolation.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.com.vectara.serving.LinearInterpolation} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.vectara.serving.LinearInterpolation.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getLambda();
  if (f !== 0.0) {
    writer.writeFloat(
      1,
      f
    );
  }
};


/**
 * optional float lambda = 1;
 * @return {number}
 */
proto.com.vectara.serving.LinearInterpolation.prototype.getLambda = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 1, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.com.vectara.serving.LinearInterpolation} returns this
 */
proto.com.vectara.serving.LinearInterpolation.prototype.setLambda = function(value) {
  return jspb.Message.setProto3FloatField(this, 1, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.com.vectara.serving.CorpusKey.repeatedFields_ = [20];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.com.vectara.serving.CorpusKey.prototype.toObject = function(opt_includeInstance) {
  return proto.com.vectara.serving.CorpusKey.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.com.vectara.serving.CorpusKey} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.vectara.serving.CorpusKey.toObject = function(includeInstance, msg) {
  var f, obj = {
    customerId: jspb.Message.getFieldWithDefault(msg, 5, 0),
    corpusId: jspb.Message.getFieldWithDefault(msg, 10, 0),
    semantics: jspb.Message.getFieldWithDefault(msg, 15, 0),
    dimList: jspb.Message.toObjectList(msg.getDimList(),
    proto.com.vectara.serving.CustomDimension.toObject, includeInstance),
    metadataFilter: jspb.Message.getFieldWithDefault(msg, 25, ""),
    lexicalInterpolationConfig: (f = msg.getLexicalInterpolationConfig()) && proto.com.vectara.serving.LinearInterpolation.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.com.vectara.serving.CorpusKey}
 */
proto.com.vectara.serving.CorpusKey.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.com.vectara.serving.CorpusKey;
  return proto.com.vectara.serving.CorpusKey.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.com.vectara.serving.CorpusKey} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.com.vectara.serving.CorpusKey}
 */
proto.com.vectara.serving.CorpusKey.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 5:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setCustomerId(value);
      break;
    case 10:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setCorpusId(value);
      break;
    case 15:
      var value = /** @type {!proto.com.vectara.serving.CorpusKey.Semantics} */ (reader.readEnum());
      msg.setSemantics(value);
      break;
    case 20:
      var value = new proto.com.vectara.serving.CustomDimension;
      reader.readMessage(value,proto.com.vectara.serving.CustomDimension.deserializeBinaryFromReader);
      msg.addDim(value);
      break;
    case 25:
      var value = /** @type {string} */ (reader.readString());
      msg.setMetadataFilter(value);
      break;
    case 30:
      var value = new proto.com.vectara.serving.LinearInterpolation;
      reader.readMessage(value,proto.com.vectara.serving.LinearInterpolation.deserializeBinaryFromReader);
      msg.setLexicalInterpolationConfig(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.com.vectara.serving.CorpusKey.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.com.vectara.serving.CorpusKey.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.com.vectara.serving.CorpusKey} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.vectara.serving.CorpusKey.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getCustomerId();
  if (f !== 0) {
    writer.writeUint32(
      5,
      f
    );
  }
  f = message.getCorpusId();
  if (f !== 0) {
    writer.writeUint32(
      10,
      f
    );
  }
  f = message.getSemantics();
  if (f !== 0.0) {
    writer.writeEnum(
      15,
      f
    );
  }
  f = message.getDimList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      20,
      f,
      proto.com.vectara.serving.CustomDimension.serializeBinaryToWriter
    );
  }
  f = message.getMetadataFilter();
  if (f.length > 0) {
    writer.writeString(
      25,
      f
    );
  }
  f = message.getLexicalInterpolationConfig();
  if (f != null) {
    writer.writeMessage(
      30,
      f,
      proto.com.vectara.serving.LinearInterpolation.serializeBinaryToWriter
    );
  }
};


/**
 * @enum {number}
 */
proto.com.vectara.serving.CorpusKey.Semantics = {
  DEFAULT: 0,
  QUERY: 1,
  RESPONSE: 2
};

/**
 * optional uint32 customer_id = 5;
 * @return {number}
 */
proto.com.vectara.serving.CorpusKey.prototype.getCustomerId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};


/**
 * @param {number} value
 * @return {!proto.com.vectara.serving.CorpusKey} returns this
 */
proto.com.vectara.serving.CorpusKey.prototype.setCustomerId = function(value) {
  return jspb.Message.setProto3IntField(this, 5, value);
};


/**
 * optional uint32 corpus_id = 10;
 * @return {number}
 */
proto.com.vectara.serving.CorpusKey.prototype.getCorpusId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 10, 0));
};


/**
 * @param {number} value
 * @return {!proto.com.vectara.serving.CorpusKey} returns this
 */
proto.com.vectara.serving.CorpusKey.prototype.setCorpusId = function(value) {
  return jspb.Message.setProto3IntField(this, 10, value);
};


/**
 * optional Semantics semantics = 15;
 * @return {!proto.com.vectara.serving.CorpusKey.Semantics}
 */
proto.com.vectara.serving.CorpusKey.prototype.getSemantics = function() {
  return /** @type {!proto.com.vectara.serving.CorpusKey.Semantics} */ (jspb.Message.getFieldWithDefault(this, 15, 0));
};


/**
 * @param {!proto.com.vectara.serving.CorpusKey.Semantics} value
 * @return {!proto.com.vectara.serving.CorpusKey} returns this
 */
proto.com.vectara.serving.CorpusKey.prototype.setSemantics = function(value) {
  return jspb.Message.setProto3EnumField(this, 15, value);
};


/**
 * repeated CustomDimension dim = 20;
 * @return {!Array<!proto.com.vectara.serving.CustomDimension>}
 */
proto.com.vectara.serving.CorpusKey.prototype.getDimList = function() {
  return /** @type{!Array<!proto.com.vectara.serving.CustomDimension>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.com.vectara.serving.CustomDimension, 20));
};


/**
 * @param {!Array<!proto.com.vectara.serving.CustomDimension>} value
 * @return {!proto.com.vectara.serving.CorpusKey} returns this
*/
proto.com.vectara.serving.CorpusKey.prototype.setDimList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 20, value);
};


/**
 * @param {!proto.com.vectara.serving.CustomDimension=} opt_value
 * @param {number=} opt_index
 * @return {!proto.com.vectara.serving.CustomDimension}
 */
proto.com.vectara.serving.CorpusKey.prototype.addDim = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 20, opt_value, proto.com.vectara.serving.CustomDimension, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.com.vectara.serving.CorpusKey} returns this
 */
proto.com.vectara.serving.CorpusKey.prototype.clearDimList = function() {
  return this.setDimList([]);
};


/**
 * optional string metadata_filter = 25;
 * @return {string}
 */
proto.com.vectara.serving.CorpusKey.prototype.getMetadataFilter = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 25, ""));
};


/**
 * @param {string} value
 * @return {!proto.com.vectara.serving.CorpusKey} returns this
 */
proto.com.vectara.serving.CorpusKey.prototype.setMetadataFilter = function(value) {
  return jspb.Message.setProto3StringField(this, 25, value);
};


/**
 * optional LinearInterpolation lexical_interpolation_config = 30;
 * @return {?proto.com.vectara.serving.LinearInterpolation}
 */
proto.com.vectara.serving.CorpusKey.prototype.getLexicalInterpolationConfig = function() {
  return /** @type{?proto.com.vectara.serving.LinearInterpolation} */ (
    jspb.Message.getWrapperField(this, proto.com.vectara.serving.LinearInterpolation, 30));
};


/**
 * @param {?proto.com.vectara.serving.LinearInterpolation|undefined} value
 * @return {!proto.com.vectara.serving.CorpusKey} returns this
*/
proto.com.vectara.serving.CorpusKey.prototype.setLexicalInterpolationConfig = function(value) {
  return jspb.Message.setWrapperField(this, 30, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.com.vectara.serving.CorpusKey} returns this
 */
proto.com.vectara.serving.CorpusKey.prototype.clearLexicalInterpolationConfig = function() {
  return this.setLexicalInterpolationConfig(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.com.vectara.serving.CorpusKey.prototype.hasLexicalInterpolationConfig = function() {
  return jspb.Message.getField(this, 30) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.com.vectara.serving.SummarizationRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.com.vectara.serving.SummarizationRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.com.vectara.serving.SummarizationRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.vectara.serving.SummarizationRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    summarizerPromptName: jspb.Message.getFieldWithDefault(msg, 3, ""),
    maxSummarizedResults: jspb.Message.getFieldWithDefault(msg, 15, 0),
    responseLang: jspb.Message.getFieldWithDefault(msg, 20, ""),
    summarizerPromptId: jspb.Message.getFieldWithDefault(msg, 5, 0),
    promptText: jspb.Message.getFieldWithDefault(msg, 200, ""),
    debug: jspb.Message.getBooleanFieldWithDefault(msg, 205, false),
    responseChars: jspb.Message.getFieldWithDefault(msg, 210, 0),
    modelParams: (f = msg.getModelParams()) && proto.com.vectara.serving.SummarizationRequest.ModelParams.toObject(includeInstance, f),
    citationParams: (f = msg.getCitationParams()) && proto.com.vectara.serving.CitationParams.toObject(includeInstance, f),
    chat: (f = msg.getChat()) && proto.com.vectara.serving.ChatRequest.toObject(includeInstance, f),
    factualConsistencyScore: jspb.Message.getBooleanFieldWithDefault(msg, 230, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.com.vectara.serving.SummarizationRequest}
 */
proto.com.vectara.serving.SummarizationRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.com.vectara.serving.SummarizationRequest;
  return proto.com.vectara.serving.SummarizationRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.com.vectara.serving.SummarizationRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.com.vectara.serving.SummarizationRequest}
 */
proto.com.vectara.serving.SummarizationRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setSummarizerPromptName(value);
      break;
    case 15:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setMaxSummarizedResults(value);
      break;
    case 20:
      var value = /** @type {string} */ (reader.readString());
      msg.setResponseLang(value);
      break;
    case 5:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setSummarizerPromptId(value);
      break;
    case 200:
      var value = /** @type {string} */ (reader.readString());
      msg.setPromptText(value);
      break;
    case 205:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setDebug(value);
      break;
    case 210:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setResponseChars(value);
      break;
    case 215:
      var value = new proto.com.vectara.serving.SummarizationRequest.ModelParams;
      reader.readMessage(value,proto.com.vectara.serving.SummarizationRequest.ModelParams.deserializeBinaryFromReader);
      msg.setModelParams(value);
      break;
    case 220:
      var value = new proto.com.vectara.serving.CitationParams;
      reader.readMessage(value,proto.com.vectara.serving.CitationParams.deserializeBinaryFromReader);
      msg.setCitationParams(value);
      break;
    case 225:
      var value = new proto.com.vectara.serving.ChatRequest;
      reader.readMessage(value,proto.com.vectara.serving.ChatRequest.deserializeBinaryFromReader);
      msg.setChat(value);
      break;
    case 230:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setFactualConsistencyScore(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.com.vectara.serving.SummarizationRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.com.vectara.serving.SummarizationRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.com.vectara.serving.SummarizationRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.vectara.serving.SummarizationRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSummarizerPromptName();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getMaxSummarizedResults();
  if (f !== 0) {
    writer.writeUint32(
      15,
      f
    );
  }
  f = message.getResponseLang();
  if (f.length > 0) {
    writer.writeString(
      20,
      f
    );
  }
  f = message.getSummarizerPromptId();
  if (f !== 0) {
    writer.writeUint32(
      5,
      f
    );
  }
  f = message.getPromptText();
  if (f.length > 0) {
    writer.writeString(
      200,
      f
    );
  }
  f = message.getDebug();
  if (f) {
    writer.writeBool(
      205,
      f
    );
  }
  f = message.getResponseChars();
  if (f !== 0) {
    writer.writeUint32(
      210,
      f
    );
  }
  f = message.getModelParams();
  if (f != null) {
    writer.writeMessage(
      215,
      f,
      proto.com.vectara.serving.SummarizationRequest.ModelParams.serializeBinaryToWriter
    );
  }
  f = message.getCitationParams();
  if (f != null) {
    writer.writeMessage(
      220,
      f,
      proto.com.vectara.serving.CitationParams.serializeBinaryToWriter
    );
  }
  f = message.getChat();
  if (f != null) {
    writer.writeMessage(
      225,
      f,
      proto.com.vectara.serving.ChatRequest.serializeBinaryToWriter
    );
  }
  f = /** @type {boolean} */ (jspb.Message.getField(message, 230));
  if (f != null) {
    writer.writeBool(
      230,
      f
    );
  }
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.com.vectara.serving.SummarizationRequest.ModelParams.prototype.toObject = function(opt_includeInstance) {
  return proto.com.vectara.serving.SummarizationRequest.ModelParams.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.com.vectara.serving.SummarizationRequest.ModelParams} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.vectara.serving.SummarizationRequest.ModelParams.toObject = function(includeInstance, msg) {
  var f, obj = {
    maxTokens: jspb.Message.getFieldWithDefault(msg, 5, 0),
    temperature: jspb.Message.getFloatingPointFieldWithDefault(msg, 10, 0.0),
    frequencyPenalty: jspb.Message.getFloatingPointFieldWithDefault(msg, 15, 0.0),
    presencePenalty: jspb.Message.getFloatingPointFieldWithDefault(msg, 20, 0.0),
    seed: jspb.Message.getFieldWithDefault(msg, 25, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.com.vectara.serving.SummarizationRequest.ModelParams}
 */
proto.com.vectara.serving.SummarizationRequest.ModelParams.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.com.vectara.serving.SummarizationRequest.ModelParams;
  return proto.com.vectara.serving.SummarizationRequest.ModelParams.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.com.vectara.serving.SummarizationRequest.ModelParams} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.com.vectara.serving.SummarizationRequest.ModelParams}
 */
proto.com.vectara.serving.SummarizationRequest.ModelParams.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 5:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setMaxTokens(value);
      break;
    case 10:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setTemperature(value);
      break;
    case 15:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setFrequencyPenalty(value);
      break;
    case 20:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setPresencePenalty(value);
      break;
    case 25:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setSeed(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.com.vectara.serving.SummarizationRequest.ModelParams.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.com.vectara.serving.SummarizationRequest.ModelParams.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.com.vectara.serving.SummarizationRequest.ModelParams} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.vectara.serving.SummarizationRequest.ModelParams.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = /** @type {number} */ (jspb.Message.getField(message, 5));
  if (f != null) {
    writer.writeUint32(
      5,
      f
    );
  }
  f = /** @type {number} */ (jspb.Message.getField(message, 10));
  if (f != null) {
    writer.writeFloat(
      10,
      f
    );
  }
  f = /** @type {number} */ (jspb.Message.getField(message, 15));
  if (f != null) {
    writer.writeFloat(
      15,
      f
    );
  }
  f = /** @type {number} */ (jspb.Message.getField(message, 20));
  if (f != null) {
    writer.writeFloat(
      20,
      f
    );
  }
  f = /** @type {number} */ (jspb.Message.getField(message, 25));
  if (f != null) {
    writer.writeUint32(
      25,
      f
    );
  }
};


/**
 * optional uint32 max_tokens = 5;
 * @return {number}
 */
proto.com.vectara.serving.SummarizationRequest.ModelParams.prototype.getMaxTokens = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};


/**
 * @param {number} value
 * @return {!proto.com.vectara.serving.SummarizationRequest.ModelParams} returns this
 */
proto.com.vectara.serving.SummarizationRequest.ModelParams.prototype.setMaxTokens = function(value) {
  return jspb.Message.setField(this, 5, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.com.vectara.serving.SummarizationRequest.ModelParams} returns this
 */
proto.com.vectara.serving.SummarizationRequest.ModelParams.prototype.clearMaxTokens = function() {
  return jspb.Message.setField(this, 5, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.com.vectara.serving.SummarizationRequest.ModelParams.prototype.hasMaxTokens = function() {
  return jspb.Message.getField(this, 5) != null;
};


/**
 * optional float temperature = 10;
 * @return {number}
 */
proto.com.vectara.serving.SummarizationRequest.ModelParams.prototype.getTemperature = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 10, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.com.vectara.serving.SummarizationRequest.ModelParams} returns this
 */
proto.com.vectara.serving.SummarizationRequest.ModelParams.prototype.setTemperature = function(value) {
  return jspb.Message.setField(this, 10, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.com.vectara.serving.SummarizationRequest.ModelParams} returns this
 */
proto.com.vectara.serving.SummarizationRequest.ModelParams.prototype.clearTemperature = function() {
  return jspb.Message.setField(this, 10, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.com.vectara.serving.SummarizationRequest.ModelParams.prototype.hasTemperature = function() {
  return jspb.Message.getField(this, 10) != null;
};


/**
 * optional float frequency_penalty = 15;
 * @return {number}
 */
proto.com.vectara.serving.SummarizationRequest.ModelParams.prototype.getFrequencyPenalty = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 15, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.com.vectara.serving.SummarizationRequest.ModelParams} returns this
 */
proto.com.vectara.serving.SummarizationRequest.ModelParams.prototype.setFrequencyPenalty = function(value) {
  return jspb.Message.setField(this, 15, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.com.vectara.serving.SummarizationRequest.ModelParams} returns this
 */
proto.com.vectara.serving.SummarizationRequest.ModelParams.prototype.clearFrequencyPenalty = function() {
  return jspb.Message.setField(this, 15, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.com.vectara.serving.SummarizationRequest.ModelParams.prototype.hasFrequencyPenalty = function() {
  return jspb.Message.getField(this, 15) != null;
};


/**
 * optional float presence_penalty = 20;
 * @return {number}
 */
proto.com.vectara.serving.SummarizationRequest.ModelParams.prototype.getPresencePenalty = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 20, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.com.vectara.serving.SummarizationRequest.ModelParams} returns this
 */
proto.com.vectara.serving.SummarizationRequest.ModelParams.prototype.setPresencePenalty = function(value) {
  return jspb.Message.setField(this, 20, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.com.vectara.serving.SummarizationRequest.ModelParams} returns this
 */
proto.com.vectara.serving.SummarizationRequest.ModelParams.prototype.clearPresencePenalty = function() {
  return jspb.Message.setField(this, 20, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.com.vectara.serving.SummarizationRequest.ModelParams.prototype.hasPresencePenalty = function() {
  return jspb.Message.getField(this, 20) != null;
};


/**
 * optional uint32 seed = 25;
 * @return {number}
 */
proto.com.vectara.serving.SummarizationRequest.ModelParams.prototype.getSeed = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 25, 0));
};


/**
 * @param {number} value
 * @return {!proto.com.vectara.serving.SummarizationRequest.ModelParams} returns this
 */
proto.com.vectara.serving.SummarizationRequest.ModelParams.prototype.setSeed = function(value) {
  return jspb.Message.setField(this, 25, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.com.vectara.serving.SummarizationRequest.ModelParams} returns this
 */
proto.com.vectara.serving.SummarizationRequest.ModelParams.prototype.clearSeed = function() {
  return jspb.Message.setField(this, 25, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.com.vectara.serving.SummarizationRequest.ModelParams.prototype.hasSeed = function() {
  return jspb.Message.getField(this, 25) != null;
};


/**
 * optional string summarizer_prompt_name = 3;
 * @return {string}
 */
proto.com.vectara.serving.SummarizationRequest.prototype.getSummarizerPromptName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.com.vectara.serving.SummarizationRequest} returns this
 */
proto.com.vectara.serving.SummarizationRequest.prototype.setSummarizerPromptName = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional uint32 max_summarized_results = 15;
 * @return {number}
 */
proto.com.vectara.serving.SummarizationRequest.prototype.getMaxSummarizedResults = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 15, 0));
};


/**
 * @param {number} value
 * @return {!proto.com.vectara.serving.SummarizationRequest} returns this
 */
proto.com.vectara.serving.SummarizationRequest.prototype.setMaxSummarizedResults = function(value) {
  return jspb.Message.setProto3IntField(this, 15, value);
};


/**
 * optional string response_lang = 20;
 * @return {string}
 */
proto.com.vectara.serving.SummarizationRequest.prototype.getResponseLang = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 20, ""));
};


/**
 * @param {string} value
 * @return {!proto.com.vectara.serving.SummarizationRequest} returns this
 */
proto.com.vectara.serving.SummarizationRequest.prototype.setResponseLang = function(value) {
  return jspb.Message.setProto3StringField(this, 20, value);
};


/**
 * optional uint32 summarizer_prompt_id = 5;
 * @return {number}
 */
proto.com.vectara.serving.SummarizationRequest.prototype.getSummarizerPromptId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};


/**
 * @param {number} value
 * @return {!proto.com.vectara.serving.SummarizationRequest} returns this
 */
proto.com.vectara.serving.SummarizationRequest.prototype.setSummarizerPromptId = function(value) {
  return jspb.Message.setProto3IntField(this, 5, value);
};


/**
 * optional string prompt_text = 200;
 * @return {string}
 */
proto.com.vectara.serving.SummarizationRequest.prototype.getPromptText = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 200, ""));
};


/**
 * @param {string} value
 * @return {!proto.com.vectara.serving.SummarizationRequest} returns this
 */
proto.com.vectara.serving.SummarizationRequest.prototype.setPromptText = function(value) {
  return jspb.Message.setProto3StringField(this, 200, value);
};


/**
 * optional bool debug = 205;
 * @return {boolean}
 */
proto.com.vectara.serving.SummarizationRequest.prototype.getDebug = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 205, false));
};


/**
 * @param {boolean} value
 * @return {!proto.com.vectara.serving.SummarizationRequest} returns this
 */
proto.com.vectara.serving.SummarizationRequest.prototype.setDebug = function(value) {
  return jspb.Message.setProto3BooleanField(this, 205, value);
};


/**
 * optional uint32 response_chars = 210;
 * @return {number}
 */
proto.com.vectara.serving.SummarizationRequest.prototype.getResponseChars = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 210, 0));
};


/**
 * @param {number} value
 * @return {!proto.com.vectara.serving.SummarizationRequest} returns this
 */
proto.com.vectara.serving.SummarizationRequest.prototype.setResponseChars = function(value) {
  return jspb.Message.setProto3IntField(this, 210, value);
};


/**
 * optional ModelParams model_params = 215;
 * @return {?proto.com.vectara.serving.SummarizationRequest.ModelParams}
 */
proto.com.vectara.serving.SummarizationRequest.prototype.getModelParams = function() {
  return /** @type{?proto.com.vectara.serving.SummarizationRequest.ModelParams} */ (
    jspb.Message.getWrapperField(this, proto.com.vectara.serving.SummarizationRequest.ModelParams, 215));
};


/**
 * @param {?proto.com.vectara.serving.SummarizationRequest.ModelParams|undefined} value
 * @return {!proto.com.vectara.serving.SummarizationRequest} returns this
*/
proto.com.vectara.serving.SummarizationRequest.prototype.setModelParams = function(value) {
  return jspb.Message.setWrapperField(this, 215, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.com.vectara.serving.SummarizationRequest} returns this
 */
proto.com.vectara.serving.SummarizationRequest.prototype.clearModelParams = function() {
  return this.setModelParams(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.com.vectara.serving.SummarizationRequest.prototype.hasModelParams = function() {
  return jspb.Message.getField(this, 215) != null;
};


/**
 * optional CitationParams citation_params = 220;
 * @return {?proto.com.vectara.serving.CitationParams}
 */
proto.com.vectara.serving.SummarizationRequest.prototype.getCitationParams = function() {
  return /** @type{?proto.com.vectara.serving.CitationParams} */ (
    jspb.Message.getWrapperField(this, proto.com.vectara.serving.CitationParams, 220));
};


/**
 * @param {?proto.com.vectara.serving.CitationParams|undefined} value
 * @return {!proto.com.vectara.serving.SummarizationRequest} returns this
*/
proto.com.vectara.serving.SummarizationRequest.prototype.setCitationParams = function(value) {
  return jspb.Message.setWrapperField(this, 220, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.com.vectara.serving.SummarizationRequest} returns this
 */
proto.com.vectara.serving.SummarizationRequest.prototype.clearCitationParams = function() {
  return this.setCitationParams(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.com.vectara.serving.SummarizationRequest.prototype.hasCitationParams = function() {
  return jspb.Message.getField(this, 220) != null;
};


/**
 * optional ChatRequest chat = 225;
 * @return {?proto.com.vectara.serving.ChatRequest}
 */
proto.com.vectara.serving.SummarizationRequest.prototype.getChat = function() {
  return /** @type{?proto.com.vectara.serving.ChatRequest} */ (
    jspb.Message.getWrapperField(this, proto.com.vectara.serving.ChatRequest, 225));
};


/**
 * @param {?proto.com.vectara.serving.ChatRequest|undefined} value
 * @return {!proto.com.vectara.serving.SummarizationRequest} returns this
*/
proto.com.vectara.serving.SummarizationRequest.prototype.setChat = function(value) {
  return jspb.Message.setWrapperField(this, 225, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.com.vectara.serving.SummarizationRequest} returns this
 */
proto.com.vectara.serving.SummarizationRequest.prototype.clearChat = function() {
  return this.setChat(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.com.vectara.serving.SummarizationRequest.prototype.hasChat = function() {
  return jspb.Message.getField(this, 225) != null;
};


/**
 * optional bool factual_consistency_score = 230;
 * @return {boolean}
 */
proto.com.vectara.serving.SummarizationRequest.prototype.getFactualConsistencyScore = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 230, false));
};


/**
 * @param {boolean} value
 * @return {!proto.com.vectara.serving.SummarizationRequest} returns this
 */
proto.com.vectara.serving.SummarizationRequest.prototype.setFactualConsistencyScore = function(value) {
  return jspb.Message.setField(this, 230, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.com.vectara.serving.SummarizationRequest} returns this
 */
proto.com.vectara.serving.SummarizationRequest.prototype.clearFactualConsistencyScore = function() {
  return jspb.Message.setField(this, 230, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.com.vectara.serving.SummarizationRequest.prototype.hasFactualConsistencyScore = function() {
  return jspb.Message.getField(this, 230) != null;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.com.vectara.serving.QueryRequest.repeatedFields_ = [25,35];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.com.vectara.serving.QueryRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.com.vectara.serving.QueryRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.com.vectara.serving.QueryRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.vectara.serving.QueryRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    query: jspb.Message.getFieldWithDefault(msg, 5, ""),
    queryContext: jspb.Message.getFieldWithDefault(msg, 10, ""),
    start: jspb.Message.getFieldWithDefault(msg, 15, 0),
    numResults: jspb.Message.getFieldWithDefault(msg, 20, 0),
    contextConfig: (f = msg.getContextConfig()) && proto.com.vectara.serving.QueryRequest.ContextConfig.toObject(includeInstance, f),
    corpusKeyList: jspb.Message.toObjectList(msg.getCorpusKeyList(),
    proto.com.vectara.serving.CorpusKey.toObject, includeInstance),
    rerankingConfig: (f = msg.getRerankingConfig()) && proto.com.vectara.serving.QueryRequest.RerankingConfig.toObject(includeInstance, f),
    summaryList: jspb.Message.toObjectList(msg.getSummaryList(),
    proto.com.vectara.serving.SummarizationRequest.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.com.vectara.serving.QueryRequest}
 */
proto.com.vectara.serving.QueryRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.com.vectara.serving.QueryRequest;
  return proto.com.vectara.serving.QueryRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.com.vectara.serving.QueryRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.com.vectara.serving.QueryRequest}
 */
proto.com.vectara.serving.QueryRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setQuery(value);
      break;
    case 10:
      var value = /** @type {string} */ (reader.readString());
      msg.setQueryContext(value);
      break;
    case 15:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setStart(value);
      break;
    case 20:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setNumResults(value);
      break;
    case 22:
      var value = new proto.com.vectara.serving.QueryRequest.ContextConfig;
      reader.readMessage(value,proto.com.vectara.serving.QueryRequest.ContextConfig.deserializeBinaryFromReader);
      msg.setContextConfig(value);
      break;
    case 25:
      var value = new proto.com.vectara.serving.CorpusKey;
      reader.readMessage(value,proto.com.vectara.serving.CorpusKey.deserializeBinaryFromReader);
      msg.addCorpusKey(value);
      break;
    case 30:
      var value = new proto.com.vectara.serving.QueryRequest.RerankingConfig;
      reader.readMessage(value,proto.com.vectara.serving.QueryRequest.RerankingConfig.deserializeBinaryFromReader);
      msg.setRerankingConfig(value);
      break;
    case 35:
      var value = new proto.com.vectara.serving.SummarizationRequest;
      reader.readMessage(value,proto.com.vectara.serving.SummarizationRequest.deserializeBinaryFromReader);
      msg.addSummary(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.com.vectara.serving.QueryRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.com.vectara.serving.QueryRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.com.vectara.serving.QueryRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.vectara.serving.QueryRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getQuery();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getQueryContext();
  if (f.length > 0) {
    writer.writeString(
      10,
      f
    );
  }
  f = message.getStart();
  if (f !== 0) {
    writer.writeUint32(
      15,
      f
    );
  }
  f = message.getNumResults();
  if (f !== 0) {
    writer.writeUint32(
      20,
      f
    );
  }
  f = message.getContextConfig();
  if (f != null) {
    writer.writeMessage(
      22,
      f,
      proto.com.vectara.serving.QueryRequest.ContextConfig.serializeBinaryToWriter
    );
  }
  f = message.getCorpusKeyList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      25,
      f,
      proto.com.vectara.serving.CorpusKey.serializeBinaryToWriter
    );
  }
  f = message.getRerankingConfig();
  if (f != null) {
    writer.writeMessage(
      30,
      f,
      proto.com.vectara.serving.QueryRequest.RerankingConfig.serializeBinaryToWriter
    );
  }
  f = message.getSummaryList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      35,
      f,
      proto.com.vectara.serving.SummarizationRequest.serializeBinaryToWriter
    );
  }
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.com.vectara.serving.QueryRequest.ContextConfig.prototype.toObject = function(opt_includeInstance) {
  return proto.com.vectara.serving.QueryRequest.ContextConfig.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.com.vectara.serving.QueryRequest.ContextConfig} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.vectara.serving.QueryRequest.ContextConfig.toObject = function(includeInstance, msg) {
  var f, obj = {
    charsBefore: jspb.Message.getFieldWithDefault(msg, 5, 0),
    charsAfter: jspb.Message.getFieldWithDefault(msg, 10, 0),
    sentencesBefore: jspb.Message.getFieldWithDefault(msg, 15, 0),
    sentencesAfter: jspb.Message.getFieldWithDefault(msg, 20, 0),
    startTag: jspb.Message.getFieldWithDefault(msg, 25, ""),
    endTag: jspb.Message.getFieldWithDefault(msg, 30, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.com.vectara.serving.QueryRequest.ContextConfig}
 */
proto.com.vectara.serving.QueryRequest.ContextConfig.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.com.vectara.serving.QueryRequest.ContextConfig;
  return proto.com.vectara.serving.QueryRequest.ContextConfig.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.com.vectara.serving.QueryRequest.ContextConfig} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.com.vectara.serving.QueryRequest.ContextConfig}
 */
proto.com.vectara.serving.QueryRequest.ContextConfig.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 5:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setCharsBefore(value);
      break;
    case 10:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setCharsAfter(value);
      break;
    case 15:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setSentencesBefore(value);
      break;
    case 20:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setSentencesAfter(value);
      break;
    case 25:
      var value = /** @type {string} */ (reader.readString());
      msg.setStartTag(value);
      break;
    case 30:
      var value = /** @type {string} */ (reader.readString());
      msg.setEndTag(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.com.vectara.serving.QueryRequest.ContextConfig.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.com.vectara.serving.QueryRequest.ContextConfig.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.com.vectara.serving.QueryRequest.ContextConfig} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.vectara.serving.QueryRequest.ContextConfig.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getCharsBefore();
  if (f !== 0) {
    writer.writeInt32(
      5,
      f
    );
  }
  f = message.getCharsAfter();
  if (f !== 0) {
    writer.writeInt32(
      10,
      f
    );
  }
  f = message.getSentencesBefore();
  if (f !== 0) {
    writer.writeInt32(
      15,
      f
    );
  }
  f = message.getSentencesAfter();
  if (f !== 0) {
    writer.writeInt32(
      20,
      f
    );
  }
  f = message.getStartTag();
  if (f.length > 0) {
    writer.writeString(
      25,
      f
    );
  }
  f = message.getEndTag();
  if (f.length > 0) {
    writer.writeString(
      30,
      f
    );
  }
};


/**
 * optional int32 chars_before = 5;
 * @return {number}
 */
proto.com.vectara.serving.QueryRequest.ContextConfig.prototype.getCharsBefore = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};


/**
 * @param {number} value
 * @return {!proto.com.vectara.serving.QueryRequest.ContextConfig} returns this
 */
proto.com.vectara.serving.QueryRequest.ContextConfig.prototype.setCharsBefore = function(value) {
  return jspb.Message.setProto3IntField(this, 5, value);
};


/**
 * optional int32 chars_after = 10;
 * @return {number}
 */
proto.com.vectara.serving.QueryRequest.ContextConfig.prototype.getCharsAfter = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 10, 0));
};


/**
 * @param {number} value
 * @return {!proto.com.vectara.serving.QueryRequest.ContextConfig} returns this
 */
proto.com.vectara.serving.QueryRequest.ContextConfig.prototype.setCharsAfter = function(value) {
  return jspb.Message.setProto3IntField(this, 10, value);
};


/**
 * optional int32 sentences_before = 15;
 * @return {number}
 */
proto.com.vectara.serving.QueryRequest.ContextConfig.prototype.getSentencesBefore = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 15, 0));
};


/**
 * @param {number} value
 * @return {!proto.com.vectara.serving.QueryRequest.ContextConfig} returns this
 */
proto.com.vectara.serving.QueryRequest.ContextConfig.prototype.setSentencesBefore = function(value) {
  return jspb.Message.setProto3IntField(this, 15, value);
};


/**
 * optional int32 sentences_after = 20;
 * @return {number}
 */
proto.com.vectara.serving.QueryRequest.ContextConfig.prototype.getSentencesAfter = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 20, 0));
};


/**
 * @param {number} value
 * @return {!proto.com.vectara.serving.QueryRequest.ContextConfig} returns this
 */
proto.com.vectara.serving.QueryRequest.ContextConfig.prototype.setSentencesAfter = function(value) {
  return jspb.Message.setProto3IntField(this, 20, value);
};


/**
 * optional string start_tag = 25;
 * @return {string}
 */
proto.com.vectara.serving.QueryRequest.ContextConfig.prototype.getStartTag = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 25, ""));
};


/**
 * @param {string} value
 * @return {!proto.com.vectara.serving.QueryRequest.ContextConfig} returns this
 */
proto.com.vectara.serving.QueryRequest.ContextConfig.prototype.setStartTag = function(value) {
  return jspb.Message.setProto3StringField(this, 25, value);
};


/**
 * optional string end_tag = 30;
 * @return {string}
 */
proto.com.vectara.serving.QueryRequest.ContextConfig.prototype.getEndTag = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 30, ""));
};


/**
 * @param {string} value
 * @return {!proto.com.vectara.serving.QueryRequest.ContextConfig} returns this
 */
proto.com.vectara.serving.QueryRequest.ContextConfig.prototype.setEndTag = function(value) {
  return jspb.Message.setProto3StringField(this, 30, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.com.vectara.serving.QueryRequest.RerankingConfig.prototype.toObject = function(opt_includeInstance) {
  return proto.com.vectara.serving.QueryRequest.RerankingConfig.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.com.vectara.serving.QueryRequest.RerankingConfig} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.vectara.serving.QueryRequest.RerankingConfig.toObject = function(includeInstance, msg) {
  var f, obj = {
    rerankerId: jspb.Message.getFieldWithDefault(msg, 5, 0),
    rerankerName: jspb.Message.getFieldWithDefault(msg, 6, ""),
    mmrConfig: (f = msg.getMmrConfig()) && proto.com.vectara.serving.MMRConfig.toObject(includeInstance, f),
    userFunction: jspb.Message.getFieldWithDefault(msg, 105, ""),
    nextRerankingConfig: (f = msg.getNextRerankingConfig()) && proto.com.vectara.serving.QueryRequest.RerankingConfig.toObject(includeInstance, f),
    limit: jspb.Message.getFieldWithDefault(msg, 115, 0),
    cutoff: jspb.Message.getFloatingPointFieldWithDefault(msg, 120, 0.0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.com.vectara.serving.QueryRequest.RerankingConfig}
 */
proto.com.vectara.serving.QueryRequest.RerankingConfig.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.com.vectara.serving.QueryRequest.RerankingConfig;
  return proto.com.vectara.serving.QueryRequest.RerankingConfig.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.com.vectara.serving.QueryRequest.RerankingConfig} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.com.vectara.serving.QueryRequest.RerankingConfig}
 */
proto.com.vectara.serving.QueryRequest.RerankingConfig.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 5:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setRerankerId(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setRerankerName(value);
      break;
    case 100:
      var value = new proto.com.vectara.serving.MMRConfig;
      reader.readMessage(value,proto.com.vectara.serving.MMRConfig.deserializeBinaryFromReader);
      msg.setMmrConfig(value);
      break;
    case 105:
      var value = /** @type {string} */ (reader.readString());
      msg.setUserFunction(value);
      break;
    case 110:
      var value = new proto.com.vectara.serving.QueryRequest.RerankingConfig;
      reader.readMessage(value,proto.com.vectara.serving.QueryRequest.RerankingConfig.deserializeBinaryFromReader);
      msg.setNextRerankingConfig(value);
      break;
    case 115:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setLimit(value);
      break;
    case 120:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setCutoff(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.com.vectara.serving.QueryRequest.RerankingConfig.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.com.vectara.serving.QueryRequest.RerankingConfig.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.com.vectara.serving.QueryRequest.RerankingConfig} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.vectara.serving.QueryRequest.RerankingConfig.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getRerankerId();
  if (f !== 0) {
    writer.writeUint32(
      5,
      f
    );
  }
  f = message.getRerankerName();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
  f = message.getMmrConfig();
  if (f != null) {
    writer.writeMessage(
      100,
      f,
      proto.com.vectara.serving.MMRConfig.serializeBinaryToWriter
    );
  }
  f = /** @type {string} */ (jspb.Message.getField(message, 105));
  if (f != null) {
    writer.writeString(
      105,
      f
    );
  }
  f = message.getNextRerankingConfig();
  if (f != null) {
    writer.writeMessage(
      110,
      f,
      proto.com.vectara.serving.QueryRequest.RerankingConfig.serializeBinaryToWriter
    );
  }
  f = /** @type {number} */ (jspb.Message.getField(message, 115));
  if (f != null) {
    writer.writeUint32(
      115,
      f
    );
  }
  f = /** @type {number} */ (jspb.Message.getField(message, 120));
  if (f != null) {
    writer.writeFloat(
      120,
      f
    );
  }
};


/**
 * optional uint32 reranker_id = 5;
 * @return {number}
 */
proto.com.vectara.serving.QueryRequest.RerankingConfig.prototype.getRerankerId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};


/**
 * @param {number} value
 * @return {!proto.com.vectara.serving.QueryRequest.RerankingConfig} returns this
 */
proto.com.vectara.serving.QueryRequest.RerankingConfig.prototype.setRerankerId = function(value) {
  return jspb.Message.setProto3IntField(this, 5, value);
};


/**
 * optional string reranker_name = 6;
 * @return {string}
 */
proto.com.vectara.serving.QueryRequest.RerankingConfig.prototype.getRerankerName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.com.vectara.serving.QueryRequest.RerankingConfig} returns this
 */
proto.com.vectara.serving.QueryRequest.RerankingConfig.prototype.setRerankerName = function(value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};


/**
 * optional MMRConfig mmr_config = 100;
 * @return {?proto.com.vectara.serving.MMRConfig}
 */
proto.com.vectara.serving.QueryRequest.RerankingConfig.prototype.getMmrConfig = function() {
  return /** @type{?proto.com.vectara.serving.MMRConfig} */ (
    jspb.Message.getWrapperField(this, proto.com.vectara.serving.MMRConfig, 100));
};


/**
 * @param {?proto.com.vectara.serving.MMRConfig|undefined} value
 * @return {!proto.com.vectara.serving.QueryRequest.RerankingConfig} returns this
*/
proto.com.vectara.serving.QueryRequest.RerankingConfig.prototype.setMmrConfig = function(value) {
  return jspb.Message.setWrapperField(this, 100, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.com.vectara.serving.QueryRequest.RerankingConfig} returns this
 */
proto.com.vectara.serving.QueryRequest.RerankingConfig.prototype.clearMmrConfig = function() {
  return this.setMmrConfig(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.com.vectara.serving.QueryRequest.RerankingConfig.prototype.hasMmrConfig = function() {
  return jspb.Message.getField(this, 100) != null;
};


/**
 * optional string user_function = 105;
 * @return {string}
 */
proto.com.vectara.serving.QueryRequest.RerankingConfig.prototype.getUserFunction = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 105, ""));
};


/**
 * @param {string} value
 * @return {!proto.com.vectara.serving.QueryRequest.RerankingConfig} returns this
 */
proto.com.vectara.serving.QueryRequest.RerankingConfig.prototype.setUserFunction = function(value) {
  return jspb.Message.setField(this, 105, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.com.vectara.serving.QueryRequest.RerankingConfig} returns this
 */
proto.com.vectara.serving.QueryRequest.RerankingConfig.prototype.clearUserFunction = function() {
  return jspb.Message.setField(this, 105, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.com.vectara.serving.QueryRequest.RerankingConfig.prototype.hasUserFunction = function() {
  return jspb.Message.getField(this, 105) != null;
};


/**
 * optional RerankingConfig next_reranking_config = 110;
 * @return {?proto.com.vectara.serving.QueryRequest.RerankingConfig}
 */
proto.com.vectara.serving.QueryRequest.RerankingConfig.prototype.getNextRerankingConfig = function() {
  return /** @type{?proto.com.vectara.serving.QueryRequest.RerankingConfig} */ (
    jspb.Message.getWrapperField(this, proto.com.vectara.serving.QueryRequest.RerankingConfig, 110));
};


/**
 * @param {?proto.com.vectara.serving.QueryRequest.RerankingConfig|undefined} value
 * @return {!proto.com.vectara.serving.QueryRequest.RerankingConfig} returns this
*/
proto.com.vectara.serving.QueryRequest.RerankingConfig.prototype.setNextRerankingConfig = function(value) {
  return jspb.Message.setWrapperField(this, 110, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.com.vectara.serving.QueryRequest.RerankingConfig} returns this
 */
proto.com.vectara.serving.QueryRequest.RerankingConfig.prototype.clearNextRerankingConfig = function() {
  return this.setNextRerankingConfig(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.com.vectara.serving.QueryRequest.RerankingConfig.prototype.hasNextRerankingConfig = function() {
  return jspb.Message.getField(this, 110) != null;
};


/**
 * optional uint32 limit = 115;
 * @return {number}
 */
proto.com.vectara.serving.QueryRequest.RerankingConfig.prototype.getLimit = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 115, 0));
};


/**
 * @param {number} value
 * @return {!proto.com.vectara.serving.QueryRequest.RerankingConfig} returns this
 */
proto.com.vectara.serving.QueryRequest.RerankingConfig.prototype.setLimit = function(value) {
  return jspb.Message.setField(this, 115, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.com.vectara.serving.QueryRequest.RerankingConfig} returns this
 */
proto.com.vectara.serving.QueryRequest.RerankingConfig.prototype.clearLimit = function() {
  return jspb.Message.setField(this, 115, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.com.vectara.serving.QueryRequest.RerankingConfig.prototype.hasLimit = function() {
  return jspb.Message.getField(this, 115) != null;
};


/**
 * optional float cutoff = 120;
 * @return {number}
 */
proto.com.vectara.serving.QueryRequest.RerankingConfig.prototype.getCutoff = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 120, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.com.vectara.serving.QueryRequest.RerankingConfig} returns this
 */
proto.com.vectara.serving.QueryRequest.RerankingConfig.prototype.setCutoff = function(value) {
  return jspb.Message.setField(this, 120, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.com.vectara.serving.QueryRequest.RerankingConfig} returns this
 */
proto.com.vectara.serving.QueryRequest.RerankingConfig.prototype.clearCutoff = function() {
  return jspb.Message.setField(this, 120, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.com.vectara.serving.QueryRequest.RerankingConfig.prototype.hasCutoff = function() {
  return jspb.Message.getField(this, 120) != null;
};


/**
 * optional string query = 5;
 * @return {string}
 */
proto.com.vectara.serving.QueryRequest.prototype.getQuery = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.com.vectara.serving.QueryRequest} returns this
 */
proto.com.vectara.serving.QueryRequest.prototype.setQuery = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional string query_context = 10;
 * @return {string}
 */
proto.com.vectara.serving.QueryRequest.prototype.getQueryContext = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 10, ""));
};


/**
 * @param {string} value
 * @return {!proto.com.vectara.serving.QueryRequest} returns this
 */
proto.com.vectara.serving.QueryRequest.prototype.setQueryContext = function(value) {
  return jspb.Message.setProto3StringField(this, 10, value);
};


/**
 * optional uint32 start = 15;
 * @return {number}
 */
proto.com.vectara.serving.QueryRequest.prototype.getStart = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 15, 0));
};


/**
 * @param {number} value
 * @return {!proto.com.vectara.serving.QueryRequest} returns this
 */
proto.com.vectara.serving.QueryRequest.prototype.setStart = function(value) {
  return jspb.Message.setProto3IntField(this, 15, value);
};


/**
 * optional uint32 num_results = 20;
 * @return {number}
 */
proto.com.vectara.serving.QueryRequest.prototype.getNumResults = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 20, 0));
};


/**
 * @param {number} value
 * @return {!proto.com.vectara.serving.QueryRequest} returns this
 */
proto.com.vectara.serving.QueryRequest.prototype.setNumResults = function(value) {
  return jspb.Message.setProto3IntField(this, 20, value);
};


/**
 * optional ContextConfig context_config = 22;
 * @return {?proto.com.vectara.serving.QueryRequest.ContextConfig}
 */
proto.com.vectara.serving.QueryRequest.prototype.getContextConfig = function() {
  return /** @type{?proto.com.vectara.serving.QueryRequest.ContextConfig} */ (
    jspb.Message.getWrapperField(this, proto.com.vectara.serving.QueryRequest.ContextConfig, 22));
};


/**
 * @param {?proto.com.vectara.serving.QueryRequest.ContextConfig|undefined} value
 * @return {!proto.com.vectara.serving.QueryRequest} returns this
*/
proto.com.vectara.serving.QueryRequest.prototype.setContextConfig = function(value) {
  return jspb.Message.setWrapperField(this, 22, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.com.vectara.serving.QueryRequest} returns this
 */
proto.com.vectara.serving.QueryRequest.prototype.clearContextConfig = function() {
  return this.setContextConfig(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.com.vectara.serving.QueryRequest.prototype.hasContextConfig = function() {
  return jspb.Message.getField(this, 22) != null;
};


/**
 * repeated CorpusKey corpus_key = 25;
 * @return {!Array<!proto.com.vectara.serving.CorpusKey>}
 */
proto.com.vectara.serving.QueryRequest.prototype.getCorpusKeyList = function() {
  return /** @type{!Array<!proto.com.vectara.serving.CorpusKey>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.com.vectara.serving.CorpusKey, 25));
};


/**
 * @param {!Array<!proto.com.vectara.serving.CorpusKey>} value
 * @return {!proto.com.vectara.serving.QueryRequest} returns this
*/
proto.com.vectara.serving.QueryRequest.prototype.setCorpusKeyList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 25, value);
};


/**
 * @param {!proto.com.vectara.serving.CorpusKey=} opt_value
 * @param {number=} opt_index
 * @return {!proto.com.vectara.serving.CorpusKey}
 */
proto.com.vectara.serving.QueryRequest.prototype.addCorpusKey = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 25, opt_value, proto.com.vectara.serving.CorpusKey, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.com.vectara.serving.QueryRequest} returns this
 */
proto.com.vectara.serving.QueryRequest.prototype.clearCorpusKeyList = function() {
  return this.setCorpusKeyList([]);
};


/**
 * optional RerankingConfig reranking_config = 30;
 * @return {?proto.com.vectara.serving.QueryRequest.RerankingConfig}
 */
proto.com.vectara.serving.QueryRequest.prototype.getRerankingConfig = function() {
  return /** @type{?proto.com.vectara.serving.QueryRequest.RerankingConfig} */ (
    jspb.Message.getWrapperField(this, proto.com.vectara.serving.QueryRequest.RerankingConfig, 30));
};


/**
 * @param {?proto.com.vectara.serving.QueryRequest.RerankingConfig|undefined} value
 * @return {!proto.com.vectara.serving.QueryRequest} returns this
*/
proto.com.vectara.serving.QueryRequest.prototype.setRerankingConfig = function(value) {
  return jspb.Message.setWrapperField(this, 30, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.com.vectara.serving.QueryRequest} returns this
 */
proto.com.vectara.serving.QueryRequest.prototype.clearRerankingConfig = function() {
  return this.setRerankingConfig(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.com.vectara.serving.QueryRequest.prototype.hasRerankingConfig = function() {
  return jspb.Message.getField(this, 30) != null;
};


/**
 * repeated SummarizationRequest summary = 35;
 * @return {!Array<!proto.com.vectara.serving.SummarizationRequest>}
 */
proto.com.vectara.serving.QueryRequest.prototype.getSummaryList = function() {
  return /** @type{!Array<!proto.com.vectara.serving.SummarizationRequest>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.com.vectara.serving.SummarizationRequest, 35));
};


/**
 * @param {!Array<!proto.com.vectara.serving.SummarizationRequest>} value
 * @return {!proto.com.vectara.serving.QueryRequest} returns this
*/
proto.com.vectara.serving.QueryRequest.prototype.setSummaryList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 35, value);
};


/**
 * @param {!proto.com.vectara.serving.SummarizationRequest=} opt_value
 * @param {number=} opt_index
 * @return {!proto.com.vectara.serving.SummarizationRequest}
 */
proto.com.vectara.serving.QueryRequest.prototype.addSummary = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 35, opt_value, proto.com.vectara.serving.SummarizationRequest, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.com.vectara.serving.QueryRequest} returns this
 */
proto.com.vectara.serving.QueryRequest.prototype.clearSummaryList = function() {
  return this.setSummaryList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.com.vectara.serving.CitationParams.prototype.toObject = function(opt_includeInstance) {
  return proto.com.vectara.serving.CitationParams.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.com.vectara.serving.CitationParams} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.vectara.serving.CitationParams.toObject = function(includeInstance, msg) {
  var f, obj = {
    style: jspb.Message.getFieldWithDefault(msg, 5, 0),
    urlPattern: jspb.Message.getFieldWithDefault(msg, 10, ""),
    textPattern: jspb.Message.getFieldWithDefault(msg, 15, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.com.vectara.serving.CitationParams}
 */
proto.com.vectara.serving.CitationParams.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.com.vectara.serving.CitationParams;
  return proto.com.vectara.serving.CitationParams.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.com.vectara.serving.CitationParams} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.com.vectara.serving.CitationParams}
 */
proto.com.vectara.serving.CitationParams.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 5:
      var value = /** @type {!proto.com.vectara.serving.CitationStyle} */ (reader.readEnum());
      msg.setStyle(value);
      break;
    case 10:
      var value = /** @type {string} */ (reader.readString());
      msg.setUrlPattern(value);
      break;
    case 15:
      var value = /** @type {string} */ (reader.readString());
      msg.setTextPattern(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.com.vectara.serving.CitationParams.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.com.vectara.serving.CitationParams.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.com.vectara.serving.CitationParams} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.vectara.serving.CitationParams.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getStyle();
  if (f !== 0.0) {
    writer.writeEnum(
      5,
      f
    );
  }
  f = /** @type {string} */ (jspb.Message.getField(message, 10));
  if (f != null) {
    writer.writeString(
      10,
      f
    );
  }
  f = /** @type {string} */ (jspb.Message.getField(message, 15));
  if (f != null) {
    writer.writeString(
      15,
      f
    );
  }
};


/**
 * optional CitationStyle style = 5;
 * @return {!proto.com.vectara.serving.CitationStyle}
 */
proto.com.vectara.serving.CitationParams.prototype.getStyle = function() {
  return /** @type {!proto.com.vectara.serving.CitationStyle} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};


/**
 * @param {!proto.com.vectara.serving.CitationStyle} value
 * @return {!proto.com.vectara.serving.CitationParams} returns this
 */
proto.com.vectara.serving.CitationParams.prototype.setStyle = function(value) {
  return jspb.Message.setProto3EnumField(this, 5, value);
};


/**
 * optional string url_pattern = 10;
 * @return {string}
 */
proto.com.vectara.serving.CitationParams.prototype.getUrlPattern = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 10, ""));
};


/**
 * @param {string} value
 * @return {!proto.com.vectara.serving.CitationParams} returns this
 */
proto.com.vectara.serving.CitationParams.prototype.setUrlPattern = function(value) {
  return jspb.Message.setField(this, 10, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.com.vectara.serving.CitationParams} returns this
 */
proto.com.vectara.serving.CitationParams.prototype.clearUrlPattern = function() {
  return jspb.Message.setField(this, 10, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.com.vectara.serving.CitationParams.prototype.hasUrlPattern = function() {
  return jspb.Message.getField(this, 10) != null;
};


/**
 * optional string text_pattern = 15;
 * @return {string}
 */
proto.com.vectara.serving.CitationParams.prototype.getTextPattern = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 15, ""));
};


/**
 * @param {string} value
 * @return {!proto.com.vectara.serving.CitationParams} returns this
 */
proto.com.vectara.serving.CitationParams.prototype.setTextPattern = function(value) {
  return jspb.Message.setField(this, 15, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.com.vectara.serving.CitationParams} returns this
 */
proto.com.vectara.serving.CitationParams.prototype.clearTextPattern = function() {
  return jspb.Message.setField(this, 15, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.com.vectara.serving.CitationParams.prototype.hasTextPattern = function() {
  return jspb.Message.getField(this, 15) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.com.vectara.serving.ChatRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.com.vectara.serving.ChatRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.com.vectara.serving.ChatRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.vectara.serving.ChatRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    store: jspb.Message.getBooleanFieldWithDefault(msg, 5, false),
    conversationId: jspb.Message.getFieldWithDefault(msg, 15, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.com.vectara.serving.ChatRequest}
 */
proto.com.vectara.serving.ChatRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.com.vectara.serving.ChatRequest;
  return proto.com.vectara.serving.ChatRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.com.vectara.serving.ChatRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.com.vectara.serving.ChatRequest}
 */
proto.com.vectara.serving.ChatRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 5:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setStore(value);
      break;
    case 15:
      var value = /** @type {string} */ (reader.readString());
      msg.setConversationId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.com.vectara.serving.ChatRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.com.vectara.serving.ChatRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.com.vectara.serving.ChatRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.vectara.serving.ChatRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getStore();
  if (f) {
    writer.writeBool(
      5,
      f
    );
  }
  f = message.getConversationId();
  if (f.length > 0) {
    writer.writeString(
      15,
      f
    );
  }
};


/**
 * optional bool store = 5;
 * @return {boolean}
 */
proto.com.vectara.serving.ChatRequest.prototype.getStore = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 5, false));
};


/**
 * @param {boolean} value
 * @return {!proto.com.vectara.serving.ChatRequest} returns this
 */
proto.com.vectara.serving.ChatRequest.prototype.setStore = function(value) {
  return jspb.Message.setProto3BooleanField(this, 5, value);
};


/**
 * optional string conversation_id = 15;
 * @return {string}
 */
proto.com.vectara.serving.ChatRequest.prototype.getConversationId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 15, ""));
};


/**
 * @param {string} value
 * @return {!proto.com.vectara.serving.ChatRequest} returns this
 */
proto.com.vectara.serving.ChatRequest.prototype.setConversationId = function(value) {
  return jspb.Message.setProto3StringField(this, 15, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.com.vectara.serving.Chat.prototype.toObject = function(opt_includeInstance) {
  return proto.com.vectara.serving.Chat.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.com.vectara.serving.Chat} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.vectara.serving.Chat.toObject = function(includeInstance, msg) {
  var f, obj = {
    conversationId: jspb.Message.getFieldWithDefault(msg, 5, ""),
    turnId: jspb.Message.getFieldWithDefault(msg, 10, ""),
    rephrasedQuery: jspb.Message.getFieldWithDefault(msg, 15, ""),
    status: (f = msg.getStatus()) && status_pb.Status.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.com.vectara.serving.Chat}
 */
proto.com.vectara.serving.Chat.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.com.vectara.serving.Chat;
  return proto.com.vectara.serving.Chat.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.com.vectara.serving.Chat} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.com.vectara.serving.Chat}
 */
proto.com.vectara.serving.Chat.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setConversationId(value);
      break;
    case 10:
      var value = /** @type {string} */ (reader.readString());
      msg.setTurnId(value);
      break;
    case 15:
      var value = /** @type {string} */ (reader.readString());
      msg.setRephrasedQuery(value);
      break;
    case 1000:
      var value = new status_pb.Status;
      reader.readMessage(value,status_pb.Status.deserializeBinaryFromReader);
      msg.setStatus(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.com.vectara.serving.Chat.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.com.vectara.serving.Chat.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.com.vectara.serving.Chat} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.vectara.serving.Chat.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getConversationId();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getTurnId();
  if (f.length > 0) {
    writer.writeString(
      10,
      f
    );
  }
  f = message.getRephrasedQuery();
  if (f.length > 0) {
    writer.writeString(
      15,
      f
    );
  }
  f = message.getStatus();
  if (f != null) {
    writer.writeMessage(
      1000,
      f,
      status_pb.Status.serializeBinaryToWriter
    );
  }
};


/**
 * optional string conversation_id = 5;
 * @return {string}
 */
proto.com.vectara.serving.Chat.prototype.getConversationId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.com.vectara.serving.Chat} returns this
 */
proto.com.vectara.serving.Chat.prototype.setConversationId = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional string turn_id = 10;
 * @return {string}
 */
proto.com.vectara.serving.Chat.prototype.getTurnId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 10, ""));
};


/**
 * @param {string} value
 * @return {!proto.com.vectara.serving.Chat} returns this
 */
proto.com.vectara.serving.Chat.prototype.setTurnId = function(value) {
  return jspb.Message.setProto3StringField(this, 10, value);
};


/**
 * optional string rephrased_query = 15;
 * @return {string}
 */
proto.com.vectara.serving.Chat.prototype.getRephrasedQuery = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 15, ""));
};


/**
 * @param {string} value
 * @return {!proto.com.vectara.serving.Chat} returns this
 */
proto.com.vectara.serving.Chat.prototype.setRephrasedQuery = function(value) {
  return jspb.Message.setProto3StringField(this, 15, value);
};


/**
 * optional com.vectara.Status status = 1000;
 * @return {?proto.com.vectara.Status}
 */
proto.com.vectara.serving.Chat.prototype.getStatus = function() {
  return /** @type{?proto.com.vectara.Status} */ (
    jspb.Message.getWrapperField(this, status_pb.Status, 1000));
};


/**
 * @param {?proto.com.vectara.Status|undefined} value
 * @return {!proto.com.vectara.serving.Chat} returns this
*/
proto.com.vectara.serving.Chat.prototype.setStatus = function(value) {
  return jspb.Message.setWrapperField(this, 1000, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.com.vectara.serving.Chat} returns this
 */
proto.com.vectara.serving.Chat.prototype.clearStatus = function() {
  return this.setStatus(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.com.vectara.serving.Chat.prototype.hasStatus = function() {
  return jspb.Message.getField(this, 1000) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.com.vectara.serving.Attribute.prototype.toObject = function(opt_includeInstance) {
  return proto.com.vectara.serving.Attribute.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.com.vectara.serving.Attribute} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.vectara.serving.Attribute.toObject = function(includeInstance, msg) {
  var f, obj = {
    name: jspb.Message.getFieldWithDefault(msg, 5, ""),
    value: jspb.Message.getFieldWithDefault(msg, 10, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.com.vectara.serving.Attribute}
 */
proto.com.vectara.serving.Attribute.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.com.vectara.serving.Attribute;
  return proto.com.vectara.serving.Attribute.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.com.vectara.serving.Attribute} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.com.vectara.serving.Attribute}
 */
proto.com.vectara.serving.Attribute.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 10:
      var value = /** @type {string} */ (reader.readString());
      msg.setValue(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.com.vectara.serving.Attribute.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.com.vectara.serving.Attribute.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.com.vectara.serving.Attribute} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.vectara.serving.Attribute.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getValue();
  if (f.length > 0) {
    writer.writeString(
      10,
      f
    );
  }
};


/**
 * optional string name = 5;
 * @return {string}
 */
proto.com.vectara.serving.Attribute.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.com.vectara.serving.Attribute} returns this
 */
proto.com.vectara.serving.Attribute.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional string value = 10;
 * @return {string}
 */
proto.com.vectara.serving.Attribute.prototype.getValue = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 10, ""));
};


/**
 * @param {string} value
 * @return {!proto.com.vectara.serving.Attribute} returns this
 */
proto.com.vectara.serving.Attribute.prototype.setValue = function(value) {
  return jspb.Message.setProto3StringField(this, 10, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.com.vectara.serving.FactualConsistency.prototype.toObject = function(opt_includeInstance) {
  return proto.com.vectara.serving.FactualConsistency.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.com.vectara.serving.FactualConsistency} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.vectara.serving.FactualConsistency.toObject = function(includeInstance, msg) {
  var f, obj = {
    score: jspb.Message.getFloatingPointFieldWithDefault(msg, 5, 0.0),
    status: (f = msg.getStatus()) && status_pb.Status.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.com.vectara.serving.FactualConsistency}
 */
proto.com.vectara.serving.FactualConsistency.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.com.vectara.serving.FactualConsistency;
  return proto.com.vectara.serving.FactualConsistency.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.com.vectara.serving.FactualConsistency} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.com.vectara.serving.FactualConsistency}
 */
proto.com.vectara.serving.FactualConsistency.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 5:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setScore(value);
      break;
    case 1000:
      var value = new status_pb.Status;
      reader.readMessage(value,status_pb.Status.deserializeBinaryFromReader);
      msg.setStatus(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.com.vectara.serving.FactualConsistency.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.com.vectara.serving.FactualConsistency.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.com.vectara.serving.FactualConsistency} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.vectara.serving.FactualConsistency.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getScore();
  if (f !== 0.0) {
    writer.writeFloat(
      5,
      f
    );
  }
  f = message.getStatus();
  if (f != null) {
    writer.writeMessage(
      1000,
      f,
      status_pb.Status.serializeBinaryToWriter
    );
  }
};


/**
 * optional float score = 5;
 * @return {number}
 */
proto.com.vectara.serving.FactualConsistency.prototype.getScore = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 5, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.com.vectara.serving.FactualConsistency} returns this
 */
proto.com.vectara.serving.FactualConsistency.prototype.setScore = function(value) {
  return jspb.Message.setProto3FloatField(this, 5, value);
};


/**
 * optional com.vectara.Status status = 1000;
 * @return {?proto.com.vectara.Status}
 */
proto.com.vectara.serving.FactualConsistency.prototype.getStatus = function() {
  return /** @type{?proto.com.vectara.Status} */ (
    jspb.Message.getWrapperField(this, status_pb.Status, 1000));
};


/**
 * @param {?proto.com.vectara.Status|undefined} value
 * @return {!proto.com.vectara.serving.FactualConsistency} returns this
*/
proto.com.vectara.serving.FactualConsistency.prototype.setStatus = function(value) {
  return jspb.Message.setWrapperField(this, 1000, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.com.vectara.serving.FactualConsistency} returns this
 */
proto.com.vectara.serving.FactualConsistency.prototype.clearStatus = function() {
  return this.setStatus(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.com.vectara.serving.FactualConsistency.prototype.hasStatus = function() {
  return jspb.Message.getField(this, 1000) != null;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.com.vectara.serving.Summary.repeatedFields_ = [1000];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.com.vectara.serving.Summary.prototype.toObject = function(opt_includeInstance) {
  return proto.com.vectara.serving.Summary.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.com.vectara.serving.Summary} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.vectara.serving.Summary.toObject = function(includeInstance, msg) {
  var f, obj = {
    text: jspb.Message.getFieldWithDefault(msg, 10, ""),
    lang: jspb.Message.getFieldWithDefault(msg, 15, ""),
    prompt: jspb.Message.getFieldWithDefault(msg, 200, ""),
    chat: (f = msg.getChat()) && proto.com.vectara.serving.Chat.toObject(includeInstance, f),
    factualConsistency: (f = msg.getFactualConsistency()) && proto.com.vectara.serving.FactualConsistency.toObject(includeInstance, f),
    done: jspb.Message.getBooleanFieldWithDefault(msg, 215, false),
    statusList: jspb.Message.toObjectList(msg.getStatusList(),
    status_pb.Status.toObject, includeInstance),
    futureId: jspb.Message.getFieldWithDefault(msg, 1010, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.com.vectara.serving.Summary}
 */
proto.com.vectara.serving.Summary.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.com.vectara.serving.Summary;
  return proto.com.vectara.serving.Summary.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.com.vectara.serving.Summary} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.com.vectara.serving.Summary}
 */
proto.com.vectara.serving.Summary.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 10:
      var value = /** @type {string} */ (reader.readString());
      msg.setText(value);
      break;
    case 15:
      var value = /** @type {string} */ (reader.readString());
      msg.setLang(value);
      break;
    case 200:
      var value = /** @type {string} */ (reader.readString());
      msg.setPrompt(value);
      break;
    case 205:
      var value = new proto.com.vectara.serving.Chat;
      reader.readMessage(value,proto.com.vectara.serving.Chat.deserializeBinaryFromReader);
      msg.setChat(value);
      break;
    case 210:
      var value = new proto.com.vectara.serving.FactualConsistency;
      reader.readMessage(value,proto.com.vectara.serving.FactualConsistency.deserializeBinaryFromReader);
      msg.setFactualConsistency(value);
      break;
    case 215:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setDone(value);
      break;
    case 1000:
      var value = new status_pb.Status;
      reader.readMessage(value,status_pb.Status.deserializeBinaryFromReader);
      msg.addStatus(value);
      break;
    case 1010:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setFutureId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.com.vectara.serving.Summary.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.com.vectara.serving.Summary.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.com.vectara.serving.Summary} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.vectara.serving.Summary.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getText();
  if (f.length > 0) {
    writer.writeString(
      10,
      f
    );
  }
  f = message.getLang();
  if (f.length > 0) {
    writer.writeString(
      15,
      f
    );
  }
  f = message.getPrompt();
  if (f.length > 0) {
    writer.writeString(
      200,
      f
    );
  }
  f = message.getChat();
  if (f != null) {
    writer.writeMessage(
      205,
      f,
      proto.com.vectara.serving.Chat.serializeBinaryToWriter
    );
  }
  f = message.getFactualConsistency();
  if (f != null) {
    writer.writeMessage(
      210,
      f,
      proto.com.vectara.serving.FactualConsistency.serializeBinaryToWriter
    );
  }
  f = message.getDone();
  if (f) {
    writer.writeBool(
      215,
      f
    );
  }
  f = message.getStatusList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1000,
      f,
      status_pb.Status.serializeBinaryToWriter
    );
  }
  f = message.getFutureId();
  if (f !== 0) {
    writer.writeInt32(
      1010,
      f
    );
  }
};


/**
 * optional string text = 10;
 * @return {string}
 */
proto.com.vectara.serving.Summary.prototype.getText = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 10, ""));
};


/**
 * @param {string} value
 * @return {!proto.com.vectara.serving.Summary} returns this
 */
proto.com.vectara.serving.Summary.prototype.setText = function(value) {
  return jspb.Message.setProto3StringField(this, 10, value);
};


/**
 * optional string lang = 15;
 * @return {string}
 */
proto.com.vectara.serving.Summary.prototype.getLang = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 15, ""));
};


/**
 * @param {string} value
 * @return {!proto.com.vectara.serving.Summary} returns this
 */
proto.com.vectara.serving.Summary.prototype.setLang = function(value) {
  return jspb.Message.setProto3StringField(this, 15, value);
};


/**
 * optional string prompt = 200;
 * @return {string}
 */
proto.com.vectara.serving.Summary.prototype.getPrompt = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 200, ""));
};


/**
 * @param {string} value
 * @return {!proto.com.vectara.serving.Summary} returns this
 */
proto.com.vectara.serving.Summary.prototype.setPrompt = function(value) {
  return jspb.Message.setProto3StringField(this, 200, value);
};


/**
 * optional Chat chat = 205;
 * @return {?proto.com.vectara.serving.Chat}
 */
proto.com.vectara.serving.Summary.prototype.getChat = function() {
  return /** @type{?proto.com.vectara.serving.Chat} */ (
    jspb.Message.getWrapperField(this, proto.com.vectara.serving.Chat, 205));
};


/**
 * @param {?proto.com.vectara.serving.Chat|undefined} value
 * @return {!proto.com.vectara.serving.Summary} returns this
*/
proto.com.vectara.serving.Summary.prototype.setChat = function(value) {
  return jspb.Message.setWrapperField(this, 205, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.com.vectara.serving.Summary} returns this
 */
proto.com.vectara.serving.Summary.prototype.clearChat = function() {
  return this.setChat(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.com.vectara.serving.Summary.prototype.hasChat = function() {
  return jspb.Message.getField(this, 205) != null;
};


/**
 * optional FactualConsistency factual_consistency = 210;
 * @return {?proto.com.vectara.serving.FactualConsistency}
 */
proto.com.vectara.serving.Summary.prototype.getFactualConsistency = function() {
  return /** @type{?proto.com.vectara.serving.FactualConsistency} */ (
    jspb.Message.getWrapperField(this, proto.com.vectara.serving.FactualConsistency, 210));
};


/**
 * @param {?proto.com.vectara.serving.FactualConsistency|undefined} value
 * @return {!proto.com.vectara.serving.Summary} returns this
*/
proto.com.vectara.serving.Summary.prototype.setFactualConsistency = function(value) {
  return jspb.Message.setWrapperField(this, 210, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.com.vectara.serving.Summary} returns this
 */
proto.com.vectara.serving.Summary.prototype.clearFactualConsistency = function() {
  return this.setFactualConsistency(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.com.vectara.serving.Summary.prototype.hasFactualConsistency = function() {
  return jspb.Message.getField(this, 210) != null;
};


/**
 * optional bool done = 215;
 * @return {boolean}
 */
proto.com.vectara.serving.Summary.prototype.getDone = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 215, false));
};


/**
 * @param {boolean} value
 * @return {!proto.com.vectara.serving.Summary} returns this
 */
proto.com.vectara.serving.Summary.prototype.setDone = function(value) {
  return jspb.Message.setProto3BooleanField(this, 215, value);
};


/**
 * repeated com.vectara.Status status = 1000;
 * @return {!Array<!proto.com.vectara.Status>}
 */
proto.com.vectara.serving.Summary.prototype.getStatusList = function() {
  return /** @type{!Array<!proto.com.vectara.Status>} */ (
    jspb.Message.getRepeatedWrapperField(this, status_pb.Status, 1000));
};


/**
 * @param {!Array<!proto.com.vectara.Status>} value
 * @return {!proto.com.vectara.serving.Summary} returns this
*/
proto.com.vectara.serving.Summary.prototype.setStatusList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1000, value);
};


/**
 * @param {!proto.com.vectara.Status=} opt_value
 * @param {number=} opt_index
 * @return {!proto.com.vectara.Status}
 */
proto.com.vectara.serving.Summary.prototype.addStatus = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1000, opt_value, proto.com.vectara.Status, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.com.vectara.serving.Summary} returns this
 */
proto.com.vectara.serving.Summary.prototype.clearStatusList = function() {
  return this.setStatusList([]);
};


/**
 * optional int32 future_id = 1010;
 * @return {number}
 */
proto.com.vectara.serving.Summary.prototype.getFutureId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1010, 0));
};


/**
 * @param {number} value
 * @return {!proto.com.vectara.serving.Summary} returns this
 */
proto.com.vectara.serving.Summary.prototype.setFutureId = function(value) {
  return jspb.Message.setProto3IntField(this, 1010, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.com.vectara.serving.Response.repeatedFields_ = [20];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.com.vectara.serving.Response.prototype.toObject = function(opt_includeInstance) {
  return proto.com.vectara.serving.Response.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.com.vectara.serving.Response} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.vectara.serving.Response.toObject = function(includeInstance, msg) {
  var f, obj = {
    text: jspb.Message.getFieldWithDefault(msg, 5, ""),
    score: jspb.Message.getFloatingPointFieldWithDefault(msg, 10, 0.0),
    metadataList: jspb.Message.toObjectList(msg.getMetadataList(),
    proto.com.vectara.serving.Attribute.toObject, includeInstance),
    documentIndex: jspb.Message.getFieldWithDefault(msg, 25, 0),
    corpusKey: (f = msg.getCorpusKey()) && proto.com.vectara.serving.CorpusKey.toObject(includeInstance, f),
    resultOffset: jspb.Message.getFieldWithDefault(msg, 35, 0),
    resultLength: jspb.Message.getFieldWithDefault(msg, 40, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.com.vectara.serving.Response}
 */
proto.com.vectara.serving.Response.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.com.vectara.serving.Response;
  return proto.com.vectara.serving.Response.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.com.vectara.serving.Response} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.com.vectara.serving.Response}
 */
proto.com.vectara.serving.Response.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setText(value);
      break;
    case 10:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setScore(value);
      break;
    case 20:
      var value = new proto.com.vectara.serving.Attribute;
      reader.readMessage(value,proto.com.vectara.serving.Attribute.deserializeBinaryFromReader);
      msg.addMetadata(value);
      break;
    case 25:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setDocumentIndex(value);
      break;
    case 30:
      var value = new proto.com.vectara.serving.CorpusKey;
      reader.readMessage(value,proto.com.vectara.serving.CorpusKey.deserializeBinaryFromReader);
      msg.setCorpusKey(value);
      break;
    case 35:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setResultOffset(value);
      break;
    case 40:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setResultLength(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.com.vectara.serving.Response.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.com.vectara.serving.Response.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.com.vectara.serving.Response} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.vectara.serving.Response.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getText();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getScore();
  if (f !== 0.0) {
    writer.writeFloat(
      10,
      f
    );
  }
  f = message.getMetadataList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      20,
      f,
      proto.com.vectara.serving.Attribute.serializeBinaryToWriter
    );
  }
  f = message.getDocumentIndex();
  if (f !== 0) {
    writer.writeUint32(
      25,
      f
    );
  }
  f = message.getCorpusKey();
  if (f != null) {
    writer.writeMessage(
      30,
      f,
      proto.com.vectara.serving.CorpusKey.serializeBinaryToWriter
    );
  }
  f = message.getResultOffset();
  if (f !== 0) {
    writer.writeInt32(
      35,
      f
    );
  }
  f = message.getResultLength();
  if (f !== 0) {
    writer.writeInt32(
      40,
      f
    );
  }
};


/**
 * optional string text = 5;
 * @return {string}
 */
proto.com.vectara.serving.Response.prototype.getText = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.com.vectara.serving.Response} returns this
 */
proto.com.vectara.serving.Response.prototype.setText = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional float score = 10;
 * @return {number}
 */
proto.com.vectara.serving.Response.prototype.getScore = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 10, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.com.vectara.serving.Response} returns this
 */
proto.com.vectara.serving.Response.prototype.setScore = function(value) {
  return jspb.Message.setProto3FloatField(this, 10, value);
};


/**
 * repeated Attribute metadata = 20;
 * @return {!Array<!proto.com.vectara.serving.Attribute>}
 */
proto.com.vectara.serving.Response.prototype.getMetadataList = function() {
  return /** @type{!Array<!proto.com.vectara.serving.Attribute>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.com.vectara.serving.Attribute, 20));
};


/**
 * @param {!Array<!proto.com.vectara.serving.Attribute>} value
 * @return {!proto.com.vectara.serving.Response} returns this
*/
proto.com.vectara.serving.Response.prototype.setMetadataList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 20, value);
};


/**
 * @param {!proto.com.vectara.serving.Attribute=} opt_value
 * @param {number=} opt_index
 * @return {!proto.com.vectara.serving.Attribute}
 */
proto.com.vectara.serving.Response.prototype.addMetadata = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 20, opt_value, proto.com.vectara.serving.Attribute, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.com.vectara.serving.Response} returns this
 */
proto.com.vectara.serving.Response.prototype.clearMetadataList = function() {
  return this.setMetadataList([]);
};


/**
 * optional uint32 document_index = 25;
 * @return {number}
 */
proto.com.vectara.serving.Response.prototype.getDocumentIndex = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 25, 0));
};


/**
 * @param {number} value
 * @return {!proto.com.vectara.serving.Response} returns this
 */
proto.com.vectara.serving.Response.prototype.setDocumentIndex = function(value) {
  return jspb.Message.setProto3IntField(this, 25, value);
};


/**
 * optional CorpusKey corpus_key = 30;
 * @return {?proto.com.vectara.serving.CorpusKey}
 */
proto.com.vectara.serving.Response.prototype.getCorpusKey = function() {
  return /** @type{?proto.com.vectara.serving.CorpusKey} */ (
    jspb.Message.getWrapperField(this, proto.com.vectara.serving.CorpusKey, 30));
};


/**
 * @param {?proto.com.vectara.serving.CorpusKey|undefined} value
 * @return {!proto.com.vectara.serving.Response} returns this
*/
proto.com.vectara.serving.Response.prototype.setCorpusKey = function(value) {
  return jspb.Message.setWrapperField(this, 30, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.com.vectara.serving.Response} returns this
 */
proto.com.vectara.serving.Response.prototype.clearCorpusKey = function() {
  return this.setCorpusKey(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.com.vectara.serving.Response.prototype.hasCorpusKey = function() {
  return jspb.Message.getField(this, 30) != null;
};


/**
 * optional int32 result_offset = 35;
 * @return {number}
 */
proto.com.vectara.serving.Response.prototype.getResultOffset = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 35, 0));
};


/**
 * @param {number} value
 * @return {!proto.com.vectara.serving.Response} returns this
 */
proto.com.vectara.serving.Response.prototype.setResultOffset = function(value) {
  return jspb.Message.setProto3IntField(this, 35, value);
};


/**
 * optional int32 result_length = 40;
 * @return {number}
 */
proto.com.vectara.serving.Response.prototype.getResultLength = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 40, 0));
};


/**
 * @param {number} value
 * @return {!proto.com.vectara.serving.Response} returns this
 */
proto.com.vectara.serving.Response.prototype.setResultLength = function(value) {
  return jspb.Message.setProto3IntField(this, 40, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.com.vectara.serving.ResponseSet.repeatedFields_ = [5,10,15,25];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.com.vectara.serving.ResponseSet.prototype.toObject = function(opt_includeInstance) {
  return proto.com.vectara.serving.ResponseSet.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.com.vectara.serving.ResponseSet} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.vectara.serving.ResponseSet.toObject = function(includeInstance, msg) {
  var f, obj = {
    responseList: jspb.Message.toObjectList(msg.getResponseList(),
    proto.com.vectara.serving.Response.toObject, includeInstance),
    statusList: jspb.Message.toObjectList(msg.getStatusList(),
    status_pb.Status.toObject, includeInstance),
    documentList: jspb.Message.toObjectList(msg.getDocumentList(),
    proto.com.vectara.serving.ResponseSet.Document.toObject, includeInstance),
    summaryList: jspb.Message.toObjectList(msg.getSummaryList(),
    proto.com.vectara.serving.Summary.toObject, includeInstance),
    queryUuid: jspb.Message.getFieldWithDefault(msg, 30, ""),
    futureId: jspb.Message.getFieldWithDefault(msg, 1010, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.com.vectara.serving.ResponseSet}
 */
proto.com.vectara.serving.ResponseSet.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.com.vectara.serving.ResponseSet;
  return proto.com.vectara.serving.ResponseSet.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.com.vectara.serving.ResponseSet} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.com.vectara.serving.ResponseSet}
 */
proto.com.vectara.serving.ResponseSet.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 5:
      var value = new proto.com.vectara.serving.Response;
      reader.readMessage(value,proto.com.vectara.serving.Response.deserializeBinaryFromReader);
      msg.addResponse(value);
      break;
    case 10:
      var value = new status_pb.Status;
      reader.readMessage(value,status_pb.Status.deserializeBinaryFromReader);
      msg.addStatus(value);
      break;
    case 15:
      var value = new proto.com.vectara.serving.ResponseSet.Document;
      reader.readMessage(value,proto.com.vectara.serving.ResponseSet.Document.deserializeBinaryFromReader);
      msg.addDocument(value);
      break;
    case 25:
      var value = new proto.com.vectara.serving.Summary;
      reader.readMessage(value,proto.com.vectara.serving.Summary.deserializeBinaryFromReader);
      msg.addSummary(value);
      break;
    case 30:
      var value = /** @type {string} */ (reader.readString());
      msg.setQueryUuid(value);
      break;
    case 1010:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setFutureId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.com.vectara.serving.ResponseSet.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.com.vectara.serving.ResponseSet.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.com.vectara.serving.ResponseSet} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.vectara.serving.ResponseSet.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getResponseList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      5,
      f,
      proto.com.vectara.serving.Response.serializeBinaryToWriter
    );
  }
  f = message.getStatusList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      10,
      f,
      status_pb.Status.serializeBinaryToWriter
    );
  }
  f = message.getDocumentList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      15,
      f,
      proto.com.vectara.serving.ResponseSet.Document.serializeBinaryToWriter
    );
  }
  f = message.getSummaryList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      25,
      f,
      proto.com.vectara.serving.Summary.serializeBinaryToWriter
    );
  }
  f = message.getQueryUuid();
  if (f.length > 0) {
    writer.writeString(
      30,
      f
    );
  }
  f = message.getFutureId();
  if (f !== 0) {
    writer.writeInt32(
      1010,
      f
    );
  }
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.com.vectara.serving.ResponseSet.Document.repeatedFields_ = [10];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.com.vectara.serving.ResponseSet.Document.prototype.toObject = function(opt_includeInstance) {
  return proto.com.vectara.serving.ResponseSet.Document.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.com.vectara.serving.ResponseSet.Document} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.vectara.serving.ResponseSet.Document.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 5, ""),
    metadataList: jspb.Message.toObjectList(msg.getMetadataList(),
    proto.com.vectara.serving.Attribute.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.com.vectara.serving.ResponseSet.Document}
 */
proto.com.vectara.serving.ResponseSet.Document.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.com.vectara.serving.ResponseSet.Document;
  return proto.com.vectara.serving.ResponseSet.Document.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.com.vectara.serving.ResponseSet.Document} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.com.vectara.serving.ResponseSet.Document}
 */
proto.com.vectara.serving.ResponseSet.Document.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 10:
      var value = new proto.com.vectara.serving.Attribute;
      reader.readMessage(value,proto.com.vectara.serving.Attribute.deserializeBinaryFromReader);
      msg.addMetadata(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.com.vectara.serving.ResponseSet.Document.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.com.vectara.serving.ResponseSet.Document.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.com.vectara.serving.ResponseSet.Document} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.vectara.serving.ResponseSet.Document.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getMetadataList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      10,
      f,
      proto.com.vectara.serving.Attribute.serializeBinaryToWriter
    );
  }
};


/**
 * optional string id = 5;
 * @return {string}
 */
proto.com.vectara.serving.ResponseSet.Document.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.com.vectara.serving.ResponseSet.Document} returns this
 */
proto.com.vectara.serving.ResponseSet.Document.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * repeated Attribute metadata = 10;
 * @return {!Array<!proto.com.vectara.serving.Attribute>}
 */
proto.com.vectara.serving.ResponseSet.Document.prototype.getMetadataList = function() {
  return /** @type{!Array<!proto.com.vectara.serving.Attribute>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.com.vectara.serving.Attribute, 10));
};


/**
 * @param {!Array<!proto.com.vectara.serving.Attribute>} value
 * @return {!proto.com.vectara.serving.ResponseSet.Document} returns this
*/
proto.com.vectara.serving.ResponseSet.Document.prototype.setMetadataList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 10, value);
};


/**
 * @param {!proto.com.vectara.serving.Attribute=} opt_value
 * @param {number=} opt_index
 * @return {!proto.com.vectara.serving.Attribute}
 */
proto.com.vectara.serving.ResponseSet.Document.prototype.addMetadata = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 10, opt_value, proto.com.vectara.serving.Attribute, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.com.vectara.serving.ResponseSet.Document} returns this
 */
proto.com.vectara.serving.ResponseSet.Document.prototype.clearMetadataList = function() {
  return this.setMetadataList([]);
};


/**
 * repeated Response response = 5;
 * @return {!Array<!proto.com.vectara.serving.Response>}
 */
proto.com.vectara.serving.ResponseSet.prototype.getResponseList = function() {
  return /** @type{!Array<!proto.com.vectara.serving.Response>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.com.vectara.serving.Response, 5));
};


/**
 * @param {!Array<!proto.com.vectara.serving.Response>} value
 * @return {!proto.com.vectara.serving.ResponseSet} returns this
*/
proto.com.vectara.serving.ResponseSet.prototype.setResponseList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 5, value);
};


/**
 * @param {!proto.com.vectara.serving.Response=} opt_value
 * @param {number=} opt_index
 * @return {!proto.com.vectara.serving.Response}
 */
proto.com.vectara.serving.ResponseSet.prototype.addResponse = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 5, opt_value, proto.com.vectara.serving.Response, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.com.vectara.serving.ResponseSet} returns this
 */
proto.com.vectara.serving.ResponseSet.prototype.clearResponseList = function() {
  return this.setResponseList([]);
};


/**
 * repeated com.vectara.Status status = 10;
 * @return {!Array<!proto.com.vectara.Status>}
 */
proto.com.vectara.serving.ResponseSet.prototype.getStatusList = function() {
  return /** @type{!Array<!proto.com.vectara.Status>} */ (
    jspb.Message.getRepeatedWrapperField(this, status_pb.Status, 10));
};


/**
 * @param {!Array<!proto.com.vectara.Status>} value
 * @return {!proto.com.vectara.serving.ResponseSet} returns this
*/
proto.com.vectara.serving.ResponseSet.prototype.setStatusList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 10, value);
};


/**
 * @param {!proto.com.vectara.Status=} opt_value
 * @param {number=} opt_index
 * @return {!proto.com.vectara.Status}
 */
proto.com.vectara.serving.ResponseSet.prototype.addStatus = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 10, opt_value, proto.com.vectara.Status, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.com.vectara.serving.ResponseSet} returns this
 */
proto.com.vectara.serving.ResponseSet.prototype.clearStatusList = function() {
  return this.setStatusList([]);
};


/**
 * repeated Document document = 15;
 * @return {!Array<!proto.com.vectara.serving.ResponseSet.Document>}
 */
proto.com.vectara.serving.ResponseSet.prototype.getDocumentList = function() {
  return /** @type{!Array<!proto.com.vectara.serving.ResponseSet.Document>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.com.vectara.serving.ResponseSet.Document, 15));
};


/**
 * @param {!Array<!proto.com.vectara.serving.ResponseSet.Document>} value
 * @return {!proto.com.vectara.serving.ResponseSet} returns this
*/
proto.com.vectara.serving.ResponseSet.prototype.setDocumentList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 15, value);
};


/**
 * @param {!proto.com.vectara.serving.ResponseSet.Document=} opt_value
 * @param {number=} opt_index
 * @return {!proto.com.vectara.serving.ResponseSet.Document}
 */
proto.com.vectara.serving.ResponseSet.prototype.addDocument = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 15, opt_value, proto.com.vectara.serving.ResponseSet.Document, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.com.vectara.serving.ResponseSet} returns this
 */
proto.com.vectara.serving.ResponseSet.prototype.clearDocumentList = function() {
  return this.setDocumentList([]);
};


/**
 * repeated Summary summary = 25;
 * @return {!Array<!proto.com.vectara.serving.Summary>}
 */
proto.com.vectara.serving.ResponseSet.prototype.getSummaryList = function() {
  return /** @type{!Array<!proto.com.vectara.serving.Summary>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.com.vectara.serving.Summary, 25));
};


/**
 * @param {!Array<!proto.com.vectara.serving.Summary>} value
 * @return {!proto.com.vectara.serving.ResponseSet} returns this
*/
proto.com.vectara.serving.ResponseSet.prototype.setSummaryList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 25, value);
};


/**
 * @param {!proto.com.vectara.serving.Summary=} opt_value
 * @param {number=} opt_index
 * @return {!proto.com.vectara.serving.Summary}
 */
proto.com.vectara.serving.ResponseSet.prototype.addSummary = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 25, opt_value, proto.com.vectara.serving.Summary, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.com.vectara.serving.ResponseSet} returns this
 */
proto.com.vectara.serving.ResponseSet.prototype.clearSummaryList = function() {
  return this.setSummaryList([]);
};


/**
 * optional string query_uuid = 30;
 * @return {string}
 */
proto.com.vectara.serving.ResponseSet.prototype.getQueryUuid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 30, ""));
};


/**
 * @param {string} value
 * @return {!proto.com.vectara.serving.ResponseSet} returns this
 */
proto.com.vectara.serving.ResponseSet.prototype.setQueryUuid = function(value) {
  return jspb.Message.setProto3StringField(this, 30, value);
};


/**
 * optional int32 future_id = 1010;
 * @return {number}
 */
proto.com.vectara.serving.ResponseSet.prototype.getFutureId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1010, 0));
};


/**
 * @param {number} value
 * @return {!proto.com.vectara.serving.ResponseSet} returns this
 */
proto.com.vectara.serving.ResponseSet.prototype.setFutureId = function(value) {
  return jspb.Message.setProto3IntField(this, 1010, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.com.vectara.serving.BatchQueryRequest.repeatedFields_ = [5];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.com.vectara.serving.BatchQueryRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.com.vectara.serving.BatchQueryRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.com.vectara.serving.BatchQueryRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.vectara.serving.BatchQueryRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    queryList: jspb.Message.toObjectList(msg.getQueryList(),
    proto.com.vectara.serving.QueryRequest.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.com.vectara.serving.BatchQueryRequest}
 */
proto.com.vectara.serving.BatchQueryRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.com.vectara.serving.BatchQueryRequest;
  return proto.com.vectara.serving.BatchQueryRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.com.vectara.serving.BatchQueryRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.com.vectara.serving.BatchQueryRequest}
 */
proto.com.vectara.serving.BatchQueryRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 5:
      var value = new proto.com.vectara.serving.QueryRequest;
      reader.readMessage(value,proto.com.vectara.serving.QueryRequest.deserializeBinaryFromReader);
      msg.addQuery(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.com.vectara.serving.BatchQueryRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.com.vectara.serving.BatchQueryRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.com.vectara.serving.BatchQueryRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.vectara.serving.BatchQueryRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getQueryList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      5,
      f,
      proto.com.vectara.serving.QueryRequest.serializeBinaryToWriter
    );
  }
};


/**
 * repeated QueryRequest query = 5;
 * @return {!Array<!proto.com.vectara.serving.QueryRequest>}
 */
proto.com.vectara.serving.BatchQueryRequest.prototype.getQueryList = function() {
  return /** @type{!Array<!proto.com.vectara.serving.QueryRequest>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.com.vectara.serving.QueryRequest, 5));
};


/**
 * @param {!Array<!proto.com.vectara.serving.QueryRequest>} value
 * @return {!proto.com.vectara.serving.BatchQueryRequest} returns this
*/
proto.com.vectara.serving.BatchQueryRequest.prototype.setQueryList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 5, value);
};


/**
 * @param {!proto.com.vectara.serving.QueryRequest=} opt_value
 * @param {number=} opt_index
 * @return {!proto.com.vectara.serving.QueryRequest}
 */
proto.com.vectara.serving.BatchQueryRequest.prototype.addQuery = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 5, opt_value, proto.com.vectara.serving.QueryRequest, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.com.vectara.serving.BatchQueryRequest} returns this
 */
proto.com.vectara.serving.BatchQueryRequest.prototype.clearQueryList = function() {
  return this.setQueryList([]);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.com.vectara.serving.BatchQueryResponse.repeatedFields_ = [5,1000];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.com.vectara.serving.BatchQueryResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.com.vectara.serving.BatchQueryResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.com.vectara.serving.BatchQueryResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.vectara.serving.BatchQueryResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    responseSetList: jspb.Message.toObjectList(msg.getResponseSetList(),
    proto.com.vectara.serving.ResponseSet.toObject, includeInstance),
    statusList: jspb.Message.toObjectList(msg.getStatusList(),
    status_pb.Status.toObject, includeInstance),
    metrics: (f = msg.getMetrics()) && proto.com.vectara.serving.PerformanceMetrics.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.com.vectara.serving.BatchQueryResponse}
 */
proto.com.vectara.serving.BatchQueryResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.com.vectara.serving.BatchQueryResponse;
  return proto.com.vectara.serving.BatchQueryResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.com.vectara.serving.BatchQueryResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.com.vectara.serving.BatchQueryResponse}
 */
proto.com.vectara.serving.BatchQueryResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 5:
      var value = new proto.com.vectara.serving.ResponseSet;
      reader.readMessage(value,proto.com.vectara.serving.ResponseSet.deserializeBinaryFromReader);
      msg.addResponseSet(value);
      break;
    case 1000:
      var value = new status_pb.Status;
      reader.readMessage(value,status_pb.Status.deserializeBinaryFromReader);
      msg.addStatus(value);
      break;
    case 1005:
      var value = new proto.com.vectara.serving.PerformanceMetrics;
      reader.readMessage(value,proto.com.vectara.serving.PerformanceMetrics.deserializeBinaryFromReader);
      msg.setMetrics(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.com.vectara.serving.BatchQueryResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.com.vectara.serving.BatchQueryResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.com.vectara.serving.BatchQueryResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.vectara.serving.BatchQueryResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getResponseSetList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      5,
      f,
      proto.com.vectara.serving.ResponseSet.serializeBinaryToWriter
    );
  }
  f = message.getStatusList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1000,
      f,
      status_pb.Status.serializeBinaryToWriter
    );
  }
  f = message.getMetrics();
  if (f != null) {
    writer.writeMessage(
      1005,
      f,
      proto.com.vectara.serving.PerformanceMetrics.serializeBinaryToWriter
    );
  }
};


/**
 * repeated ResponseSet response_set = 5;
 * @return {!Array<!proto.com.vectara.serving.ResponseSet>}
 */
proto.com.vectara.serving.BatchQueryResponse.prototype.getResponseSetList = function() {
  return /** @type{!Array<!proto.com.vectara.serving.ResponseSet>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.com.vectara.serving.ResponseSet, 5));
};


/**
 * @param {!Array<!proto.com.vectara.serving.ResponseSet>} value
 * @return {!proto.com.vectara.serving.BatchQueryResponse} returns this
*/
proto.com.vectara.serving.BatchQueryResponse.prototype.setResponseSetList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 5, value);
};


/**
 * @param {!proto.com.vectara.serving.ResponseSet=} opt_value
 * @param {number=} opt_index
 * @return {!proto.com.vectara.serving.ResponseSet}
 */
proto.com.vectara.serving.BatchQueryResponse.prototype.addResponseSet = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 5, opt_value, proto.com.vectara.serving.ResponseSet, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.com.vectara.serving.BatchQueryResponse} returns this
 */
proto.com.vectara.serving.BatchQueryResponse.prototype.clearResponseSetList = function() {
  return this.setResponseSetList([]);
};


/**
 * repeated com.vectara.Status status = 1000;
 * @return {!Array<!proto.com.vectara.Status>}
 */
proto.com.vectara.serving.BatchQueryResponse.prototype.getStatusList = function() {
  return /** @type{!Array<!proto.com.vectara.Status>} */ (
    jspb.Message.getRepeatedWrapperField(this, status_pb.Status, 1000));
};


/**
 * @param {!Array<!proto.com.vectara.Status>} value
 * @return {!proto.com.vectara.serving.BatchQueryResponse} returns this
*/
proto.com.vectara.serving.BatchQueryResponse.prototype.setStatusList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1000, value);
};


/**
 * @param {!proto.com.vectara.Status=} opt_value
 * @param {number=} opt_index
 * @return {!proto.com.vectara.Status}
 */
proto.com.vectara.serving.BatchQueryResponse.prototype.addStatus = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1000, opt_value, proto.com.vectara.Status, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.com.vectara.serving.BatchQueryResponse} returns this
 */
proto.com.vectara.serving.BatchQueryResponse.prototype.clearStatusList = function() {
  return this.setStatusList([]);
};


/**
 * optional PerformanceMetrics metrics = 1005;
 * @return {?proto.com.vectara.serving.PerformanceMetrics}
 */
proto.com.vectara.serving.BatchQueryResponse.prototype.getMetrics = function() {
  return /** @type{?proto.com.vectara.serving.PerformanceMetrics} */ (
    jspb.Message.getWrapperField(this, proto.com.vectara.serving.PerformanceMetrics, 1005));
};


/**
 * @param {?proto.com.vectara.serving.PerformanceMetrics|undefined} value
 * @return {!proto.com.vectara.serving.BatchQueryResponse} returns this
*/
proto.com.vectara.serving.BatchQueryResponse.prototype.setMetrics = function(value) {
  return jspb.Message.setWrapperField(this, 1005, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.com.vectara.serving.BatchQueryResponse} returns this
 */
proto.com.vectara.serving.BatchQueryResponse.prototype.clearMetrics = function() {
  return this.setMetrics(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.com.vectara.serving.BatchQueryResponse.prototype.hasMetrics = function() {
  return jspb.Message.getField(this, 1005) != null;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.com.vectara.serving.QueryResponsePart.repeatedFields_ = [1000];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.com.vectara.serving.QueryResponsePart.prototype.toObject = function(opt_includeInstance) {
  return proto.com.vectara.serving.QueryResponsePart.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.com.vectara.serving.QueryResponsePart} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.vectara.serving.QueryResponsePart.toObject = function(includeInstance, msg) {
  var f, obj = {
    batchQueryResponse: (f = msg.getBatchQueryResponse()) && proto.com.vectara.serving.BatchQueryResponse.toObject(includeInstance, f),
    responseSet: (f = msg.getResponseSet()) && proto.com.vectara.serving.ResponseSet.toObject(includeInstance, f),
    summary: (f = msg.getSummary()) && proto.com.vectara.serving.Summary.toObject(includeInstance, f),
    statusList: jspb.Message.toObjectList(msg.getStatusList(),
    status_pb.Status.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.com.vectara.serving.QueryResponsePart}
 */
proto.com.vectara.serving.QueryResponsePart.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.com.vectara.serving.QueryResponsePart;
  return proto.com.vectara.serving.QueryResponsePart.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.com.vectara.serving.QueryResponsePart} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.com.vectara.serving.QueryResponsePart}
 */
proto.com.vectara.serving.QueryResponsePart.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 5:
      var value = new proto.com.vectara.serving.BatchQueryResponse;
      reader.readMessage(value,proto.com.vectara.serving.BatchQueryResponse.deserializeBinaryFromReader);
      msg.setBatchQueryResponse(value);
      break;
    case 10:
      var value = new proto.com.vectara.serving.ResponseSet;
      reader.readMessage(value,proto.com.vectara.serving.ResponseSet.deserializeBinaryFromReader);
      msg.setResponseSet(value);
      break;
    case 15:
      var value = new proto.com.vectara.serving.Summary;
      reader.readMessage(value,proto.com.vectara.serving.Summary.deserializeBinaryFromReader);
      msg.setSummary(value);
      break;
    case 1000:
      var value = new status_pb.Status;
      reader.readMessage(value,status_pb.Status.deserializeBinaryFromReader);
      msg.addStatus(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.com.vectara.serving.QueryResponsePart.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.com.vectara.serving.QueryResponsePart.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.com.vectara.serving.QueryResponsePart} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.vectara.serving.QueryResponsePart.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getBatchQueryResponse();
  if (f != null) {
    writer.writeMessage(
      5,
      f,
      proto.com.vectara.serving.BatchQueryResponse.serializeBinaryToWriter
    );
  }
  f = message.getResponseSet();
  if (f != null) {
    writer.writeMessage(
      10,
      f,
      proto.com.vectara.serving.ResponseSet.serializeBinaryToWriter
    );
  }
  f = message.getSummary();
  if (f != null) {
    writer.writeMessage(
      15,
      f,
      proto.com.vectara.serving.Summary.serializeBinaryToWriter
    );
  }
  f = message.getStatusList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1000,
      f,
      status_pb.Status.serializeBinaryToWriter
    );
  }
};


/**
 * optional BatchQueryResponse batch_query_response = 5;
 * @return {?proto.com.vectara.serving.BatchQueryResponse}
 */
proto.com.vectara.serving.QueryResponsePart.prototype.getBatchQueryResponse = function() {
  return /** @type{?proto.com.vectara.serving.BatchQueryResponse} */ (
    jspb.Message.getWrapperField(this, proto.com.vectara.serving.BatchQueryResponse, 5));
};


/**
 * @param {?proto.com.vectara.serving.BatchQueryResponse|undefined} value
 * @return {!proto.com.vectara.serving.QueryResponsePart} returns this
*/
proto.com.vectara.serving.QueryResponsePart.prototype.setBatchQueryResponse = function(value) {
  return jspb.Message.setWrapperField(this, 5, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.com.vectara.serving.QueryResponsePart} returns this
 */
proto.com.vectara.serving.QueryResponsePart.prototype.clearBatchQueryResponse = function() {
  return this.setBatchQueryResponse(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.com.vectara.serving.QueryResponsePart.prototype.hasBatchQueryResponse = function() {
  return jspb.Message.getField(this, 5) != null;
};


/**
 * optional ResponseSet response_set = 10;
 * @return {?proto.com.vectara.serving.ResponseSet}
 */
proto.com.vectara.serving.QueryResponsePart.prototype.getResponseSet = function() {
  return /** @type{?proto.com.vectara.serving.ResponseSet} */ (
    jspb.Message.getWrapperField(this, proto.com.vectara.serving.ResponseSet, 10));
};


/**
 * @param {?proto.com.vectara.serving.ResponseSet|undefined} value
 * @return {!proto.com.vectara.serving.QueryResponsePart} returns this
*/
proto.com.vectara.serving.QueryResponsePart.prototype.setResponseSet = function(value) {
  return jspb.Message.setWrapperField(this, 10, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.com.vectara.serving.QueryResponsePart} returns this
 */
proto.com.vectara.serving.QueryResponsePart.prototype.clearResponseSet = function() {
  return this.setResponseSet(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.com.vectara.serving.QueryResponsePart.prototype.hasResponseSet = function() {
  return jspb.Message.getField(this, 10) != null;
};


/**
 * optional Summary summary = 15;
 * @return {?proto.com.vectara.serving.Summary}
 */
proto.com.vectara.serving.QueryResponsePart.prototype.getSummary = function() {
  return /** @type{?proto.com.vectara.serving.Summary} */ (
    jspb.Message.getWrapperField(this, proto.com.vectara.serving.Summary, 15));
};


/**
 * @param {?proto.com.vectara.serving.Summary|undefined} value
 * @return {!proto.com.vectara.serving.QueryResponsePart} returns this
*/
proto.com.vectara.serving.QueryResponsePart.prototype.setSummary = function(value) {
  return jspb.Message.setWrapperField(this, 15, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.com.vectara.serving.QueryResponsePart} returns this
 */
proto.com.vectara.serving.QueryResponsePart.prototype.clearSummary = function() {
  return this.setSummary(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.com.vectara.serving.QueryResponsePart.prototype.hasSummary = function() {
  return jspb.Message.getField(this, 15) != null;
};


/**
 * repeated com.vectara.Status status = 1000;
 * @return {!Array<!proto.com.vectara.Status>}
 */
proto.com.vectara.serving.QueryResponsePart.prototype.getStatusList = function() {
  return /** @type{!Array<!proto.com.vectara.Status>} */ (
    jspb.Message.getRepeatedWrapperField(this, status_pb.Status, 1000));
};


/**
 * @param {!Array<!proto.com.vectara.Status>} value
 * @return {!proto.com.vectara.serving.QueryResponsePart} returns this
*/
proto.com.vectara.serving.QueryResponsePart.prototype.setStatusList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1000, value);
};


/**
 * @param {!proto.com.vectara.Status=} opt_value
 * @param {number=} opt_index
 * @return {!proto.com.vectara.Status}
 */
proto.com.vectara.serving.QueryResponsePart.prototype.addStatus = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1000, opt_value, proto.com.vectara.Status, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.com.vectara.serving.QueryResponsePart} returns this
 */
proto.com.vectara.serving.QueryResponsePart.prototype.clearStatusList = function() {
  return this.setStatusList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.com.vectara.serving.PerformanceMetrics.prototype.toObject = function(opt_includeInstance) {
  return proto.com.vectara.serving.PerformanceMetrics.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.com.vectara.serving.PerformanceMetrics} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.vectara.serving.PerformanceMetrics.toObject = function(includeInstance, msg) {
  var f, obj = {
    queryEncodeMs: jspb.Message.getFieldWithDefault(msg, 5, 0),
    retrievalMs: jspb.Message.getFieldWithDefault(msg, 10, 0),
    userdataRetrievalMs: jspb.Message.getFieldWithDefault(msg, 15, 0),
    rerankMs: jspb.Message.getFieldWithDefault(msg, 20, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.com.vectara.serving.PerformanceMetrics}
 */
proto.com.vectara.serving.PerformanceMetrics.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.com.vectara.serving.PerformanceMetrics;
  return proto.com.vectara.serving.PerformanceMetrics.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.com.vectara.serving.PerformanceMetrics} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.com.vectara.serving.PerformanceMetrics}
 */
proto.com.vectara.serving.PerformanceMetrics.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 5:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setQueryEncodeMs(value);
      break;
    case 10:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setRetrievalMs(value);
      break;
    case 15:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setUserdataRetrievalMs(value);
      break;
    case 20:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setRerankMs(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.com.vectara.serving.PerformanceMetrics.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.com.vectara.serving.PerformanceMetrics.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.com.vectara.serving.PerformanceMetrics} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.vectara.serving.PerformanceMetrics.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getQueryEncodeMs();
  if (f !== 0) {
    writer.writeUint32(
      5,
      f
    );
  }
  f = message.getRetrievalMs();
  if (f !== 0) {
    writer.writeUint32(
      10,
      f
    );
  }
  f = message.getUserdataRetrievalMs();
  if (f !== 0) {
    writer.writeUint32(
      15,
      f
    );
  }
  f = message.getRerankMs();
  if (f !== 0) {
    writer.writeUint32(
      20,
      f
    );
  }
};


/**
 * optional uint32 query_encode_ms = 5;
 * @return {number}
 */
proto.com.vectara.serving.PerformanceMetrics.prototype.getQueryEncodeMs = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};


/**
 * @param {number} value
 * @return {!proto.com.vectara.serving.PerformanceMetrics} returns this
 */
proto.com.vectara.serving.PerformanceMetrics.prototype.setQueryEncodeMs = function(value) {
  return jspb.Message.setProto3IntField(this, 5, value);
};


/**
 * optional uint32 retrieval_ms = 10;
 * @return {number}
 */
proto.com.vectara.serving.PerformanceMetrics.prototype.getRetrievalMs = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 10, 0));
};


/**
 * @param {number} value
 * @return {!proto.com.vectara.serving.PerformanceMetrics} returns this
 */
proto.com.vectara.serving.PerformanceMetrics.prototype.setRetrievalMs = function(value) {
  return jspb.Message.setProto3IntField(this, 10, value);
};


/**
 * optional uint32 userdata_retrieval_ms = 15;
 * @return {number}
 */
proto.com.vectara.serving.PerformanceMetrics.prototype.getUserdataRetrievalMs = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 15, 0));
};


/**
 * @param {number} value
 * @return {!proto.com.vectara.serving.PerformanceMetrics} returns this
 */
proto.com.vectara.serving.PerformanceMetrics.prototype.setUserdataRetrievalMs = function(value) {
  return jspb.Message.setProto3IntField(this, 15, value);
};


/**
 * optional uint32 rerank_ms = 20;
 * @return {number}
 */
proto.com.vectara.serving.PerformanceMetrics.prototype.getRerankMs = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 20, 0));
};


/**
 * @param {number} value
 * @return {!proto.com.vectara.serving.PerformanceMetrics} returns this
 */
proto.com.vectara.serving.PerformanceMetrics.prototype.setRerankMs = function(value) {
  return jspb.Message.setProto3IntField(this, 20, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.com.vectara.serving.MMRConfig.prototype.toObject = function(opt_includeInstance) {
  return proto.com.vectara.serving.MMRConfig.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.com.vectara.serving.MMRConfig} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.vectara.serving.MMRConfig.toObject = function(includeInstance, msg) {
  var f, obj = {
    diversityBias: jspb.Message.getFloatingPointFieldWithDefault(msg, 5, 0.0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.com.vectara.serving.MMRConfig}
 */
proto.com.vectara.serving.MMRConfig.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.com.vectara.serving.MMRConfig;
  return proto.com.vectara.serving.MMRConfig.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.com.vectara.serving.MMRConfig} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.com.vectara.serving.MMRConfig}
 */
proto.com.vectara.serving.MMRConfig.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 5:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setDiversityBias(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.com.vectara.serving.MMRConfig.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.com.vectara.serving.MMRConfig.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.com.vectara.serving.MMRConfig} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.vectara.serving.MMRConfig.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getDiversityBias();
  if (f !== 0.0) {
    writer.writeFloat(
      5,
      f
    );
  }
};


/**
 * optional float diversity_bias = 5;
 * @return {number}
 */
proto.com.vectara.serving.MMRConfig.prototype.getDiversityBias = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 5, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.com.vectara.serving.MMRConfig} returns this
 */
proto.com.vectara.serving.MMRConfig.prototype.setDiversityBias = function(value) {
  return jspb.Message.setProto3FloatField(this, 5, value);
};


/**
 * @enum {number}
 */
proto.com.vectara.serving.CitationStyle = {
  NUMERIC: 0,
  NONE: 1,
  HTML: 2,
  MARKDOWN: 3
};

goog.object.extend(exports, proto.com.vectara.serving);
