import { createContext, useState, useContext, ReactNode, useEffect } from "react";
import { sendFetchCorporaRequest } from "../admin/CorpusEndpoint";
import { useUserContext } from "./UserContext";
import { SAMPLE_CORPUS_NAME } from "../constants";

interface GetStartedContextType {
  hasCreatedCorpus: boolean;
  setHasCreatedCorpus: (hasCreatedCorpus: boolean) => void;
}

const GetStartedContext = createContext<GetStartedContextType | undefined>(undefined);

type Props = {
  children: ReactNode;
};

export const GetStartedContextProvider = ({ children }: Props) => {
  const { customer, getJwt } = useUserContext();
  const [hasCreatedCorpus, setHasCreatedCorpus] = useState(false);

  const fetchCorpora = async () => {
    if (!customer) return [];

    try {
      const jwt = await getJwt();
      const data = await sendFetchCorporaRequest(jwt, customer.customerId, "");
      return data.corpora;
    } catch (error) {
      console.log("Error fetching corpora", error);
      return;
    }
  };

  const updateActiveSteps = async () => {
    const allCorpora = await fetchCorpora();
    const originalCorpora = allCorpora?.filter((corpus) => corpus.name !== SAMPLE_CORPUS_NAME) ?? [];

    if (originalCorpora) {
      setHasCreatedCorpus(Boolean(originalCorpora[0]));
    }
  };

  useEffect(() => {
    updateActiveSteps();
  }, []);

  return (
    <GetStartedContext.Provider
      value={{
        hasCreatedCorpus,
        setHasCreatedCorpus
      }}
    >
      {children}
    </GetStartedContext.Provider>
  );
};

export const useGetStartedContext = () => {
  const context = useContext(GetStartedContext);
  if (context === undefined) {
    throw new Error("useGetStartedContext must be used within a GetStartedContextProvider");
  }
  return context;
};
